export default theme => ({
	root: {},
	dropdownButton: {
		marginRight: -theme.spacing(2),
	},
	chartWrapper: {
		height: '400px',
		position: 'relative',
	},
	portletFooter: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
});
