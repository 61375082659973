/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import {
	Column,
	FilteringState,
	GroupingState,
	IntegratedFiltering,
	IntegratedGrouping,
	IntegratedPaging,
	IntegratedSelection,
	IntegratedSorting,
	PagingState,
	SelectionState,
	SortingState,
	DataTypeProvider,
	DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
	DragDropProvider,
	Grid,
	GroupingPanel,
	PagingPanel,
	Table,
	TableFilterRow,
	TableGroupRow,
	TableHeaderRow,
	TableSelection,
	Toolbar,
	TableColumnVisibility,
	TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import { Chip, Typography, Button, WithStyles } from '@material-ui/core';
import { CheckCircleOutline, RemoveCircleOutline, RefreshOutlined, MailOutlined } from '@material-ui/icons';

import styles from './styles';
import { withStyles } from '@material-ui/styles';
import { withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';

const SEND_CONFIRM_MAIL = gql`
	mutation sendConfirmationMail($reservationId: Int!) {
		sendConfirmationMail(id: $reservationId)
	}
`;

interface IPriority {
	id: number;
	displayName: string;
	value: number;
	color: string;
}

interface IShow {
	id: number;
	displayName: string;
}

interface IReservation {
	id: number;
	bookingCode: string;
	email: string | null;
	comment: string | null;
	firstName: string;
	lastName: string;
	seatCount: number;
	show: IShow;
	priority: IPriority;
	splitEnabled: boolean;
	confirmed: boolean;
}

interface IGridState {
	columns: Column[];
	integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[];
	integratedGroupingColumnExtensions: IntegratedGrouping.ColumnExtension[];
	pageSizes: number[];
	selection: (string | number)[];
}

interface IReservationGridProps extends WithStyles<typeof styles> {
	rows: IReservation[];
	defaultHiddenColumns?: string[];
	onReservationClick?: (reservation: IReservation) => void;
	className?: string;
	onRefreshClick?: () => void;
}

const PriorityFormatter = ({ value }: { value: IPriority }) => (
	<Chip
		key={value.id}
		label={value.displayName}
		style={{ backgroundColor: value.color, color: '#ffffff', height: '24px' }}
	/>
);

const PriorityTypeProvider = (props: DataTypeProviderProps) => (
	<DataTypeProvider formatterComponent={PriorityFormatter} {...props} />
);

const comparePriority = (priorityA: IPriority, priorityB: IPriority) => priorityA.value - priorityB.value;

const identifyPriority = (priority: { id: number }) => {
	return { key: priority.id, value: priority };
};

const ShowFormatter = ({ value }: { value: IShow }) => (
	<Typography style={{ display: 'inline' }}>{value.displayName}</Typography>
);

const ShowTypeProvider = (props: DataTypeProviderProps) => (
	<DataTypeProvider formatterComponent={ShowFormatter} {...props} />
);

const identifyShow = (priority: { id: number }) => {
	return { key: priority.id, value: priority };
};

const ConfirmedFormatter = ({ value }: { value: boolean }) =>
	value ? (
		<CheckCircleOutline style={{ color: '#80e27e' }} />
	) : (
		<RemoveCircleOutline style={{ color: '#ff7961' }} />
	);

const ConfirmedTypeProvider = (props: DataTypeProviderProps) => (
	<DataTypeProvider formatterComponent={ConfirmedFormatter} {...props} />
);

const CommentFormatter = ({ value }: { value: string | null }) =>
	value != null && value.length > 5 ? (
		<CheckCircleOutline style={{ color: '#80e27e' }} />
	) : (
		<RemoveCircleOutline style={{ color: '#ff7961' }} />
	);

const CommentTypeProvider = (props: DataTypeProviderProps) => (
	<DataTypeProvider formatterComponent={CommentFormatter} {...props} />
);

class ReservationGrid extends React.Component<WithApolloClient<IReservationGridProps>, IGridState> {
	constructor(props: any) {
		super(props);

		this.state = {
			columns: [
				{ name: 'bookingCode', title: 'Buchungscode' },
				{ name: 'firstName', title: 'Vorname' },
				{ name: 'lastName', title: 'Nachname' },
				{ name: 'email', title: 'E-Mail' },
				{ name: 'seatCount', title: 'Sitzanzahl' },
				{ name: 'show', title: 'Show' },
				{ name: 'createdAt', title: 'Bestellt am' },
				{ name: 'confirmed', title: 'Bestätigt' },
				{
					name: 'priority',
					title: 'Priorität',
					getCellValue: row => (row.priority ? row.priority : undefined),
				},
				{ name: 'comment', title: 'Kommentar' },
			],
			integratedSortingColumnExtensions: [{ columnName: 'priority', compare: comparePriority }],
			integratedGroupingColumnExtensions: [
				{ columnName: 'priority', criteria: identifyPriority },
				{ columnName: 'show', criteria: identifyShow },
			],
			pageSizes: [5, 10, 15, 25, 50, 100],
			selection: [],
		};
	}

	updateSelection = (selection: (string | number)[]) => {
		this.setState({
			selection,
		});
	};

	sendReservationEmail = async (reservationId: number) => {
		const { client } = this.props;
		await client.mutate({
			mutation: SEND_CONFIRM_MAIL,
			variables: {
				reservationId,
			},
		});
	};

	handleSendConfirmEmailClick = async () => {
		const mutations = this.state.selection.map(selected => {
			return this.sendReservationEmail(this.props.rows[Number(selected)].id);
		});
		await Promise.all(mutations);
		return true;
	};

	public render(): React.ReactNode {
		const {
			columns,
			pageSizes,
			integratedSortingColumnExtensions,
			integratedGroupingColumnExtensions,
			selection,
		} = this.state;
		const { className, rows, defaultHiddenColumns, onReservationClick, onRefreshClick, classes } = this.props;

		const TableRow = ({ row, ...restProps }: { row: IReservation }) => (
			// @ts-ignore: Type is not assignable
			<Table.Row
				{...restProps}
				onClick={() => {
					if (onReservationClick) {
						onReservationClick(row);
					}
				}}
				style={{ cursor: 'pointer' }}
			/>
		);

		return (
			<div>
				<div className={classes.row}>
					<Button
						color="primary"
						onClick={() => {
							this.handleSendConfirmEmailClick();
						}}
					>
						<MailOutlined />
						Bestätigungs-Email verschicken
					</Button>
					<span className={classes.spacer} />
					<Button
						color="primary"
						onClick={() => {
							if (onRefreshClick) {
								onRefreshClick();
							}
						}}
					>
						<RefreshOutlined />
					</Button>
				</div>
				<div className={className}>
					<Grid rows={rows} columns={columns}>
						<FilteringState />
						<SortingState />
						<SelectionState selection={selection} onSelectionChange={this.updateSelection} />
						<GroupingState />
						<PagingState />

						<IntegratedGrouping columnExtensions={integratedGroupingColumnExtensions} />
						<IntegratedFiltering />
						<IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
						<IntegratedPaging />
						<IntegratedSelection />

						<PriorityTypeProvider for={['priority']} />
						<ShowTypeProvider for={['show']} />
						<ConfirmedTypeProvider for={['confirmed']} />
						<CommentTypeProvider for={['comment']} />

						<DragDropProvider />

						<Table rowComponent={TableRow} />
						<TableSelection showSelectAll />

						<TableColumnResizing
							minColumnWidth={80}
							defaultColumnWidths={[
								{ columnName: 'bookingCode', width: 170 },
								{ columnName: 'firstName', width: 180 },
								{ columnName: 'lastName', width: 180 },
								{ columnName: 'email', width: 240 },
								{ columnName: 'seatCount', width: 120 },
								{ columnName: 'show', width: 200 },
								{ columnName: 'createdAt', width: 180 },
								{ columnName: 'confirmed', width: 100 },
								{ columnName: 'priority', width: 160 },
								{ columnName: 'comment', width: 100 },
							]}
						/>

						<TableHeaderRow showSortingControls showGroupingControls />
						<TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumns} />
						<TableFilterRow showFilterSelector />

						<PagingPanel pageSizes={pageSizes} />

						<TableGroupRow />
						<Toolbar />
						<GroupingPanel showSortingControls showGroupingControls />
					</Grid>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(withApollo<IReservationGridProps>(ReservationGrid));
