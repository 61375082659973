import { Route, Redirect, RouteProps } from 'react-router';

import React, { Component } from 'react';
import { isAuthenticated } from './auth';

interface IAuthRouteProps extends RouteProps {}

export default class AuthRoute extends Component<IAuthRouteProps, {}> {
	render() {
		const RouteComponent = this.props.component as (typeof Component);
		return (
			<Route
				{...{ ...this.props, component: undefined }}
				render={props => {
					if (isAuthenticated()) {
						if (this.props.component) {
							return <RouteComponent {...props} />;
						} else if (this.props.render) {
							return this.props.render(props);
						}
						return null;
					} else {
						return (
							<Redirect
								to={{
									pathname: '/sign-in',
									state: { from: props.location },
								}}
							/>
						);
					}
				}}
			/>
		);
	}
}
