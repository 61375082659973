import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { DialogContentText, withStyles, WithStyles } from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';

interface ISetPriorityDialogProps extends WithMobileDialog, WithStyles<typeof styles> {
	open: boolean;
	initialPriority: number;
	handleClose?: () => void;
	handleConfirm?: (value: number) => void;
}

const SetPriorityDialog: React.FC<ISetPriorityDialogProps> = function(props: ISetPriorityDialogProps) {
	const { fullScreen, open, handleClose, classes, handleConfirm, initialPriority } = props;

	return (
		<Formik
			initialValues={{ value: initialPriority }}
			validate={values => {
				return validate(values, schema);
			}}
			onSubmit={(values, { setSubmitting }) => {
				if (handleConfirm) {
					handleConfirm(Number(values.value));
				}
				if (handleClose) {
					handleClose();
				}
				setSubmitting(false);
			}}
			render={props => (
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
				>
					<DialogTitle id="responsive-dialog-title">Priorität einstellen</DialogTitle>
					<DialogContent>
						<DialogContentText>Setze die Priorität der Sitzreihen</DialogContentText>
						<Form>
							<Field
								className={classes.fullWidthField}
								name="value"
								label="Priority"
								variant="outlined"
								component={TextField}
							/>
						</Form>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Abbrechen
						</Button>
						<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
							Priorität setzen
						</Button>
					</DialogActions>
				</Dialog>
			)}
		/>
	);
};

export default withMobileDialog()(withStyles(styles)(SetPriorityDialog));
