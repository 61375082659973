import React, { Component } from 'react';
import { withStyles, WithStyles, Tabs, Tab, Paper, Grid, Typography, Button, Chip } from '@material-ui/core';

import styles from './styles';
import { withRouter, RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { ReservationAudienceSpaceView, ReservationDeleteDialog } from './components';
import { getReservationGeneralVariables, getReservationGeneral } from '__schema__/getReservationGeneral';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { deleteReservationById, deleteReservationByIdVariables } from '__schema__/deleteReservationById';

const GET_RESERVATION_GENERAL = gql`
	query getReservationGeneral($id: Int!) {
		getReservationById(id: $id) {
			id
			bookingCode
			firstName
			lastName
			email
			seatCount
			priority {
				id
				displayName
				color
			}
			show {
				id
				displayName
			}
			comment
		}
	}
`;

const DELETE_RESERVATION = gql`
	mutation deleteReservationById($reservationId: Int!) {
		deleteReservationById(id: $reservationId) {
			id
			reservations {
				id
			}
		}
	}
`;

interface RouteParams {
	identifier: string;
}

interface IReservationDetailsProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	startTab: 'overview' | 'tickets' | 'audiencespace';
	reservationId: number;
}

interface IReservationDetailsState {
	currentTab: 'overview' | 'tickets' | 'audiencespace';
	deleteDialogOpen: boolean;
}

class ReservationDetails extends Component<IReservationDetailsProps, IReservationDetailsState> {
	componentWillMount() {
		const { startTab: tab } = this.props;
		this.setState({ currentTab: tab });
	}

	handleChange = (_: any, value: 'overview' | 'tickets' | 'audiencespace') => {
		const { history, match, reservationId } = this.props;
		this.setState({
			currentTab: value,
		});
		history.push(`/p/${match.params.identifier}/reservation/${reservationId}/${value}`);
	};

	handleEditReservation = () => {
		const { history, match, reservationId } = this.props;
		history.push(`/p/${match.params.identifier}/reservation/edit/${reservationId}`);
	};

	render() {
		const { classes, reservationId, history, match } = this.props;
		const { currentTab, deleteDialogOpen } = this.state;
		return (
			<Query<getReservationGeneral, getReservationGeneralVariables>
				variables={{ id: reservationId }}
				query={GET_RESERVATION_GENERAL}
			>
				{({ data, client }) => {
					if (data && data.getReservationById) {
						return (
							<div className={classes.root}>
								<Paper className={classes.tabs}>
									<div className={classes.content}>
										<div className={classes.row}>
											<div style={{ display: 'contents' }}>
												<Typography style={{ display: 'inline' }} variant="h3">
													{`#${data.getReservationById.bookingCode}`}
												</Typography>
												<Typography
													style={{ display: 'inline' }}
													className={classes.idText}
													variant="body2"
												>{`ID: ${data.getReservationById.id}`}</Typography>
											</div>
											<span className={classes.spacer} />
											<Button
												style={{ marginRight: 4 }}
												onClick={this.handleEditReservation}
												color="primary"
												variant="outlined"
											>
												Editieren&nbsp;&nbsp;
												<EditOutlined />
											</Button>
											{/* 											<Button
												style={{ marginRight: 4 }}
												onClick={this.handleEditReservation}
												color="primary"
												variant="outlined"
											>
												Bestätigungs-Mail senden&nbsp;&nbsp;
												<MailOutlined />
											</Button> */}
											<Button
												onClick={() => {
													this.setState({
														deleteDialogOpen: true,
													});
												}}
												variant="outlined"
											>
												Löschen&nbsp;&nbsp;
												<DeleteOutlined />
											</Button>
											<ReservationDeleteDialog
												open={deleteDialogOpen}
												handleClose={() => this.setState({ deleteDialogOpen: false })}
												handleConfirm={async () => {
													this.setState({ deleteDialogOpen: false });
													try {
														await client.mutate<deleteReservationById, deleteReservationByIdVariables>({
															mutation: DELETE_RESERVATION,
															variables: {
																reservationId,
															},
														});
													} catch {
														window.alert('Bitte zuerst die Tickets entfernen');
													}
													history.push(`/p/${match.params.identifier}/reservation/`);
												}}
											/>
										</div>
										<Grid container direction="row" className={classes.details} spacing={0}>
											<Grid item xs={3} lg={2}>
												<Typography>Nachname:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getReservationById.lastName}</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography>Vorname:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getReservationById.firstName}</Typography>
											</Grid>
											<Grid item lg={4} />
											<Grid item xs={3} lg={2}>
												<Typography>E-Mail:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">
													{data.getReservationById.email == null
														? 'Keine E-Mail'
														: data.getReservationById.email}
												</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography>Sitzanzahl:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getReservationById.seatCount}</Typography>
											</Grid>
											<Grid item lg={4} />
											<Grid item xs={3} lg={2}>
												<Typography>Show:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getReservationById.show.displayName}</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography>Priorität:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Chip
													size="small"
													key={data.getReservationById.priority.id}
													label={data.getReservationById.priority.displayName}
													style={{
														backgroundColor: data.getReservationById.priority.color,
														color: '#ffffff',
														height: '24px',
													}}
												/>
											</Grid>
											<Grid item lg={4} />
											<Grid item lg={12}>
												<Typography>Kommentar:</Typography>
												<Typography color="primary">{data.getReservationById.comment}</Typography>
											</Grid>
										</Grid>
									</div>
									<Tabs
										value={currentTab}
										onChange={this.handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant="fullWidth"
										centered
									>
										<Tab className={classes.tab} value="overview" label="Überblick" />
										<Tab className={classes.tab} value="tickets" label="Tickets" />
										<Tab className={classes.tab} value="audiencespace" label="Zuschauerraum" />
									</Tabs>
								</Paper>

								{currentTab === 'audiencespace' && (
									<ReservationAudienceSpaceView reservationId={reservationId} />
								)}
							</div>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(withRouter<IReservationDetailsProps>(ReservationDetails));
