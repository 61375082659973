import { createStyles } from '@material-ui/core';

const styles = () => {
	return createStyles({
		tableRow: {
			height: '64px',
		},
		tableCell: {
			whiteSpace: 'nowrap',
		},
		tableCellInner: {
			display: 'flex',
			alignItems: 'center',
		},
		avatarCell: {
			paddingRight: '0px',
		},
		avatar: {
			fontSize: '14px',
			fontWeight: 500,
			height: '36px',
			width: '36px',
			borderRadius: '18px',
		},
		nameText: {
			display: 'inline-block',
			fontWeight: 500,
			cursor: 'pointer',
		},
	});
};

export default styles;
