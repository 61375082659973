import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, palette }: Theme) => {
	return createStyles({
		root: {
			maxWidth: '100%',
			paddingTop: spacing(2),
			paddingBottom: spacing(2),
			paddingRight: spacing(3),
			paddingLeft: spacing(3),
		},
		imageWrapper: {
			height: '64px',
			width: '64px',
			margin: '0 auto',
			borderRadius: '5px',
			overflow: 'hidden',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		image: {
			width: '100%',
			height: '100%',
		},
		details: {},
		title: {
			fontSize: '18px',
			lineHeight: '21px',
			textAlign: 'center',
			marginTop: spacing(2),
		},
		description: {
			lineHeight: '16px',
			height: spacing(4),
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			color: palette.text.secondary,
			textAlign: 'center',
			marginTop: spacing(1),
			marginBottom: spacing(2),
		},
		stats: {
			display: 'flex',
			alignItems: 'center',
			paddingTop: spacing(1),
		},
		updateIcon: {
			color: palette.text.secondary,
		},
		updateText: {
			marginLeft: spacing(1),
			color: palette.text.secondary,
		},
		downloadsIcon: {
			marginLeft: 'auto',
			color: palette.text.secondary,
		},
		downloadsText: {
			marginLeft: spacing(1),
			color: palette.text.secondary,
		},
		chip: {},
	});
};

export default styles;
