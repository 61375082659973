import React, { Component } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter, LoadingView } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	LinearProgress,
	SnackbarContent,
	MenuItem,
} from '@material-ui/core';
import styles from './styles';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import validate from 'validate.js';
import schema from './schema';
import { getShowSettings, getShowSettingsVariables } from '__schema__/getShowSettings';
import { editShow, editShowVariables } from '__schema__/editShow';

const GET_SHOW_SETTINGS = gql`
	query getShowSettings($showId: Int!) {
		getShowById(id: $showId) {
			id
			displayName
			audienceSpace {
				id
				displayName
			}
			beginsAt
			maxTicketCount
			disabled
			public
			project {
				audienceSpaces {
					id
					displayName
				}
			}
		}
	}
`;

const EDIT_SHOW = gql`
	mutation editShow(
		$showId: Int!
		$displayName: String!
		$beginsAt: DateTime!
		$maxTicketCount: Int!
		$disabled: Boolean!
		$public: Boolean!
	) {
		editShow(
			editShowData: {
				showId: $showId
				displayName: $displayName
				beginsAt: $beginsAt
				maxTicketCount: $maxTicketCount
				disabled: $disabled
				public: $public
			}
		) {
			id
			displayName
			beginsAt
			maxTicketCount
			disabled
			public
		}
	}
`;

interface IShowSettingsProps extends WithStyles<typeof styles> {
	showId: number;
}

class ShowSettings extends Component<IShowSettingsProps, {}> {
	render() {
		const { classes, showId } = this.props;
		return (
			<Query<getShowSettings, getShowSettingsVariables> variables={{ showId }} query={GET_SHOW_SETTINGS}>
				{({ data }) => {
					if (data && data.getShowById) {
						return (
							<Mutation<editShow, editShowVariables> mutation={EDIT_SHOW}>
								{(editShow, { error }) => {
									return (
										<Formik
											initialValues={{
												...data.getShowById,
												beginsAt: new Date(data.getShowById.beginsAt).toISOString().substr(0, 16),
												audienceSpaceId: data.getShowById.audienceSpace.id,
											}}
											validate={values => {
												return validate(values, schema);
											}}
											onSubmit={async (values, { setSubmitting }) => {
												try {
													const result = await editShow({
														variables: {
															showId: data.getShowById.id,
															...values,
															maxTicketCount: Number(values.maxTicketCount),
														},
													});
													if (result != null && result.data != null && result.data.editShow != null) {
														setSubmitting(false);
													} else {
														setSubmitting(false);
													}
												} catch {
													setSubmitting(false);
												}
											}}
											render={props => (
												<div className={classes.root}>
													<Portlet className={classes.portlet}>
														<PortletHeader>
															<Typography variant="h4">Showeinstellungen</Typography>
														</PortletHeader>
														<PortletContent>
															<Form>
																<Grid container spacing={2}>
																	<Grid item xs={12}>
																		<Typography color="primary" variant="h5">
																			Show
																		</Typography>
																	</Grid>
																	<Grid item xs={12} md={6}>
																		<Typography variant="h6">Anzeigename</Typography>
																		<Typography variant="body2">
																			Der Anzeigename der anstatt des Identifiers angezeigt werden soll,
																			solange dies möglich ist
																		</Typography>
																		<Field
																			className={classes.fullWidthField}
																			name="displayName"
																			label="Anzeigename"
																			variant="outlined"
																			component={TextField}
																		/>
																	</Grid>
																	<Grid item xs={12} md={6}>
																		<Typography variant="h6">Zuschauerraum</Typography>
																		<Typography variant="body2">
																			Wähle einen Zuschauerraum für diese Show aus
																		</Typography>
																		<Field
																			className={classes.fullWidthField}
																			name="audienceSpaceId"
																			disabled
																			select
																			label="Zuschauerraum"
																			variant="outlined"
																			component={TextField}
																		>
																			<MenuItem disabled key={-1} value={-1}>
																				---
																			</MenuItem>
																			{data.getShowById.project.audienceSpaces.map(audienceSpace => (
																				<MenuItem key={audienceSpace.id} value={audienceSpace.id}>
																					{audienceSpace.displayName}
																				</MenuItem>
																			))}
																		</Field>
																	</Grid>
																	<Grid item xs={12} md={6}>
																		<Typography variant="h6">Beginnt um</Typography>
																		<Typography variant="body2">
																			Lege ein Startdatum fest, nachdem keine Reservierungen mehr getätigt
																			werden können.
																		</Typography>
																		<Field
																			className={classes.fullWidthField}
																			name="beginsAt"
																			type="datetime-local"
																			label="Beginnt um"
																			variant="outlined"
																			component={TextField}
																		/>
																	</Grid>
																	<Grid item xs={12} md={6}>
																		<Typography variant="h6">Maximale Ticketanzahl</Typography>
																		<Typography variant="body2">
																			Lege eine maximale Anzahl der zur Verfügung stehenden Tickets fest (-1 =
																			maximale Sitzanzahl)
																		</Typography>
																		<Field
																			className={classes.fullWidthField}
																			name="maxTicketCount"
																			label="Maximale Ticketanzahl"
																			variant="outlined"
																			component={TextField}
																		/>
																	</Grid>
																	<Grid item xs={12} md={6} />
																	<Grid item xs={6} md={3}>
																		<Typography variant="h6">Deaktiviert</Typography>
																		<Typography variant="body2">Deaktiviere die Show</Typography>
																		<Field name="disabled" variant="outlined" component={Switch} />
																	</Grid>
																	<Grid item xs={6} md={3}>
																		<Typography variant="h6">Öffentlich</Typography>
																		<Typography variant="body2">
																			Setze die Show auf öffentlich, sodass Karten für diese gebucht werden
																			können
																		</Typography>
																		<Field name="public" variant="outlined" component={Switch} />
																	</Grid>
																</Grid>
															</Form>
														</PortletContent>
														{props.isSubmitting && <LinearProgress />}
														{error && <SnackbarContent message={error.message} />}
														<PortletFooter>
															<div className={classes.row}>
																<span className={classes.spacer} />
																<Button onClick={props.submitForm} color="primary" variant="contained">
																	Show speichern
																</Button>
															</div>
														</PortletFooter>
													</Portlet>
												</div>
											)}
										/>
									);
								}}
							</Mutation>
						);
					}
					return <LoadingView />;
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(ShowSettings);
