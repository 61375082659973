import React, { Component } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
} from '@material-ui/core';
import styles from './styles';
import { NavLink } from 'react-router-dom';
import { DomainOutlined } from '@material-ui/icons';
import { CreateAudienceSpaceDialog, EditAudienceSpace } from './components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import {
	getProjectAudienceSpacesVariables,
	getProjectAudienceSpaces,
} from '__schema__/getProjectAudienceSpaces';

const GET_PROJECT_AUDIENCE_SPACES = gql`
	query getProjectAudienceSpaces($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			audienceSpaces {
				id
				displayName
			}
		}
	}
`;

interface IAudienceSpaceSettingsProps extends WithStyles<typeof styles> {
	projectIdentifier: string;
	subKey?: string | null;
}

interface IAudienceSpaceSettingsState {
	createAudienceSpaceDialogOpen: boolean;
}

class AudienceSpaceSettings extends Component<IAudienceSpaceSettingsProps, IAudienceSpaceSettingsState> {
	state = {
		createAudienceSpaceDialogOpen: false,
	};

	openCreateAudienceSpaceDialog = () => {
		this.setState({
			createAudienceSpaceDialogOpen: true,
		});
	};

	closeCreateAudienceSpaceDialog = () => {
		this.setState({
			createAudienceSpaceDialogOpen: false,
		});
	};

	render() {
		//eslint-disable-next-line
		const { classes, projectIdentifier, subKey } = this.props;
		const { createAudienceSpaceDialogOpen } = this.state;
		return (
			<Query<getProjectAudienceSpaces, getProjectAudienceSpacesVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_AUDIENCE_SPACES}
			>
				{({ data, refetch }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<Portlet className={classes.portlet}>
								<PortletHeader>
									<Typography variant="h4">Zuschauerraumeinstellungen</Typography>
								</PortletHeader>
								<PortletContent noPadding>
									<Grid container direction="row">
										<Grid className={classes.selectionList} item xs={12} md={3} lg={2}>
											<List component="div" disablePadding>
												{data.getProjectByIdentifier.audienceSpaces.map(audienceSpace => (
													<ListItem
														key={audienceSpace.id}
														className={classes.listItem}
														activeClassName={classes.activeListItem}
														component={NavLink}
														to={`/p/${projectIdentifier}/settings/audiencespace/${audienceSpace.id}`}
													>
														<ListItemIcon className={classes.listItemIcon}>
															<DomainOutlined />
														</ListItemIcon>
														<ListItemText
															className={classes.listItemText}
															primary={audienceSpace.displayName}
														/>
													</ListItem>
												))}
											</List>
										</Grid>
										<Grid item xs={12} md={9} lg={10}>
											{subKey == null && (
												<div className={classes.center}>
													<Typography className={classes.hintText} variant="h4">
														Wähle links einen Zuschauerraum aus oder erstelle einen Neuen
													</Typography>
													<CreateAudienceSpaceDialog
														projectId={data.getProjectByIdentifier.id}
														projectIdentifier={projectIdentifier}
														handleClose={this.closeCreateAudienceSpaceDialog}
														handleCreated={refetch}
														open={createAudienceSpaceDialogOpen}
													/>
													<Button
														onClick={this.openCreateAudienceSpaceDialog}
														color="primary"
														variant="outlined"
													>
														Neuen Zuschauerraum erstellen
													</Button>
												</div>
											)}
											{subKey != null && <EditAudienceSpace audienceSpaceId={Number(subKey)} />}
										</Grid>
									</Grid>
								</PortletContent>
								<PortletFooter>
									<div className={classes.row}>
										<span className={classes.spacer} />
									</div>
								</PortletFooter>
							</Portlet>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(AudienceSpaceSettings);
