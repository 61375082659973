import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { Avatar, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// Material icons
import {
	DashboardOutlined as DashboardIcon,
	SlideshowOutlined as ShowIcon,
	BookmarksOutlined as ReservationIcon,
	InfoOutlined as InfoIcon,
	InsertChartOutlinedOutlined as ChartsIcon,
	SettingsOutlined as SettingsIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';

interface ISidebarProps extends WithStyles<typeof styles> {
	className?: string;
	projectIdentifier?: string;
	projectDisplayName?: string;
	projectImage?: string;
}

class Sidebar extends Component<ISidebarProps, {}> {
	render() {
		const { classes, className, projectIdentifier, projectDisplayName, projectImage } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<nav className={rootClassName}>
				<div className={classes.logoWrapper}>
					<Link className={classes.logoLink} to="/">
						<img alt="TicketOS logo" className={classes.logoImage} src="/images/logos/logo.png" />
					</Link>
				</div>
				<Divider className={classes.logoDivider} />
				<div className={classes.profile}>
					<Link to={`/p/${projectIdentifier}/dashboard`}>
						<Avatar alt="Roman Kutepov" className={classes.avatar} src={projectImage} />
					</Link>
					<Typography className={classes.nameText} variant="h6">
						{projectDisplayName}
					</Typography>
					<Typography className={classes.bioText} variant="caption">
						{projectIdentifier}
					</Typography>
				</div>
				<Divider className={classes.profileDivider} />
				<List component="div" disablePadding>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/p/${projectIdentifier}/dashboard`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
					</ListItem>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/p/${projectIdentifier}/show`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<ShowIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Shows" />
					</ListItem>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/p/${projectIdentifier}/reservation`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<ReservationIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Reservierungen" />
					</ListItem>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/p/${projectIdentifier}/stats`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<ChartsIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Statistiken" />
					</ListItem>
					<ListItem
						activeClassName={classes.activeListItem}
						className={classes.listItem}
						component={NavLink}
						to={`/p/${projectIdentifier}/settings`}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Projekteinstellungen" />
					</ListItem>
				</List>
				<Divider className={classes.listDivider} />
				<List component="div" disablePadding>
					<ListItem
						className={classes.listItem}
						component="a"
						href="http://docs.ticketos.io/"
						target="_blank"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<InfoIcon />
						</ListItemIcon>
						<ListItemText classes={{ primary: classes.listItemText }} primary="Dokumentation" />
					</ListItem>
				</List>
			</nav>
		);
	}
}

export default withStyles(styles)(Sidebar);
