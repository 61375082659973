import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints, palette }: Theme) => {
	return createStyles({
		content: {
			padding: spacing(1),
		},
		row: {
			display: 'flex',
			alignItems: 'center',
		},
		spacer: {
			flexGrow: 1,
		},
		portlet: {
			[breakpoints.down('sm')]: {
				borderRadius: 0,
			},
		},
		listItem: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: palette.primary.light,
				borderLeft: `4px solid ${palette.primary.main}`,
				borderRadius: '4px',
				'& $listItemIcon': {
					color: palette.primary.main,
					marginLeft: '-4px',
				},
			},
			'& + &': {
				marginTop: spacing(1),
			},
		},
		activeListItem: {
			borderLeft: `4px solid ${palette.primary.main}`,
			borderRadius: '4px',
			backgroundColor: palette.primary.light,
			'& $listItemText': {
				color: palette.text.primary,
			},
			'& $listItemIcon': {
				color: palette.primary.main,
				marginLeft: '-4px',
			},
		},
		listItemIcon: {
			marginRight: 0,
		},
		listItemText: {
			fontWeight: 500,
			color: palette.text.secondary,
		},
		listDivider: {
			marginBottom: spacing(2),
			marginTop: spacing(2),
		},
		selectionList: {
			padding: spacing(1),
			[breakpoints.up('md')]: {
				borderRightWidth: '1px',
				borderRightColor: '#DFE3E8',
				borderRightStyle: 'solid',
			},
			[breakpoints.down('xs')]: {
				borderBottomWidth: '1px',
				borderBottomColor: '#DFE3E8',
				borderBottomStyle: 'solid',
			},
		},
		center: {
			margin: 'auto',
			paddingTop: '230px',
			textAlign: 'center',
			minHeight: '600px',
		},
		hintText: {
			marginBottom: spacing(2),
		},
	});
};

export default styles;
