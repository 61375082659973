import { createStyles } from '@material-ui/core';

const styles = () => {
	return createStyles({
		fullWidthField: {
			width: '100%',
			marginBottom: '10px',
		},
	});
};

export default styles;
