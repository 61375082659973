import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles, Avatar } from '@material-ui/core';

// Material components
import { IconButton, Popover, Badge } from '@material-ui/core';

// Component styles
import styles from './styles';
import { UserTabDetails } from './components';

interface IUserIconTabProps extends WithStyles<typeof styles>, RouteComponentProps {
	className?: string;
}

interface IUserIconTabState {
	iconEl: HTMLElement | null;
}

class UserIconTab extends Component<IUserIconTabProps, IUserIconTabState> {
	state = {
		iconEl: null,
	};

	handleShowUser = (e: React.MouseEvent) => {
		this.setState({
			iconEl: e.currentTarget as HTMLElement,
		});
	};

	handleCloseUser = () => {
		this.setState({
			iconEl: null,
		});
	};

	render() {
		const { classes, className } = this.props;
		const { iconEl } = this.state;

		const rootClassName = classNames(classes.iconButton, className);
		const showPopover = Boolean(iconEl);

		return (
			<Fragment>
				<IconButton className={rootClassName} onClick={this.handleShowUser}>
					<Badge badgeContent={<p>2</p>} color="error" variant="dot">
						<Avatar
							className={classes.avatar}
							alt="Matthias Pompe"
							src="https://pbs.twimg.com/profile_images/469017630796296193/R-bEN4UP.png"
						/>
					</Badge>
				</IconButton>
				<Popover
					anchorEl={iconEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					onClose={this.handleCloseUser}
					open={showPopover}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<UserTabDetails />
				</Popover>
			</Fragment>
		);
	}
}

export default withStyles(styles)(withRouter<IUserIconTabProps>(UserIconTab));
