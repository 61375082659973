export default {
	value: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: -1,
			lessThan: 100000,
		},
	},
};
