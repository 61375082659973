import React, { Component } from 'react';
import { withStyles, WithStyles } from '@material-ui/core';

import styles from './styles';

class LoadingScreen extends Component<WithStyles<typeof styles>, {}> {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div className={classes.center}>
					<div className="sk-cube-grid">
						<div className="sk-cube sk-cube1" />
						<div className="sk-cube sk-cube2" />
						<div className="sk-cube sk-cube3" />
						<div className="sk-cube sk-cube4" />
						<div className="sk-cube sk-cube5" />
						<div className="sk-cube sk-cube6" />
						<div className="sk-cube sk-cube7" />
						<div className="sk-cube sk-cube8" />
						<div className="sk-cube sk-cube9" />
					</div>
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(LoadingScreen);
