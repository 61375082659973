import React from 'react';
import { Image } from 'react-konva';

interface ISeatProps {
	id?: number;
	taken?: boolean;
	activated?: boolean;
	highlighted?: boolean;
	x?: number;
	y?: number;
	onClick?: (id: number) => void;
	onDoubleClick?: (id: number) => void;
	disabled?: boolean;
}

interface ISeatState {
	image: ImageBitmapSource | null;
}

export default class Seat extends React.Component<ISeatProps, ISeatState> {
	static defaultProps = {
		id: -1,
		taken: false,
		activated: false,
		highlighted: false,
		x: 0,
		y: 0,
		onClick: () => {},
	};

	constructor(props: ISeatProps) {
		super(props);
		this.state = {
			image: null,
		};
	}

	componentDidMount() {
		this.updateImage(this.props);
	}

	componentWillUpdate(nextProps: ISeatProps) {
		const { taken, activated, highlighted } = this.props;
		if (
			nextProps.taken !== taken ||
			nextProps.activated !== activated ||
			nextProps.highlighted !== highlighted
		) {
			this.updateImage(nextProps);
		}
	}

	updateImage = (props: ISeatProps) => {
		// eslint-disable-next-line
		const image = new (window as any).Image();
		const activeString = props.activated ? 'active' : props.highlighted ? 'highlight' : 'inactive';
		const takenString = props.taken ? 'taken' : 'free';
		image.src = `/images/seats/seat_${activeString}_${takenString}.png`;
		image.onload = () => {
			// setState will redraw layer
			// because "image" property is changed
			this.setState({
				image: image,
			});
		};
	};

	handleClick = () => {
		if (this.props.onClick) {
			this.props.onClick(Number(this.props.id));
		}
	};

	handleDoubleClick = () => {
		if (this.props.onDoubleClick) {
			this.props.onDoubleClick(Number(this.props.id));
		}
	};

	render() {
		if (this.state.image == null) {
			return null;
		}
		return (
			<Image
				onClick={this.handleClick}
				onDblClick={this.handleDoubleClick}
				opacity={this.props.disabled ? 0.6 : 1}
				x={this.props.x}
				y={this.props.y}
				width={10}
				height={10}
				image={this.state.image as ImageBitmapSource}
			/>
		);
	}
}
