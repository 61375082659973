import { createStyles, Theme } from '@material-ui/core';

const styles = ({ spacing }: Theme) => {
	return createStyles({
		fullWidthField: {
			width: '100%',
			marginBottom: spacing(2),
		},
	});
};

export default styles;
