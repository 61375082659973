import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
	Grid,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
	editReservationPriority,
	editReservationPriorityVariables,
} from '__schema__/editReservationPriority';

const EDIT_RESERVATION_PRIORITY = gql`
	mutation editReservationPriority(
		$reservationPriorityId: Int!
		$displayName: String!
		$value: Int!
		$color: String!
	) {
		editReservationPriority(
			editReservationPriorityData: {
				reservationPriorityId: $reservationPriorityId
				displayName: $displayName
				value: $value
				color: $color
			}
		) {
			id
			displayName
			value
			color
		}
	}
`;

interface IEditReservationPriorityDialogProps extends WithMobileDialog, WithStyles<typeof styles> {
	open: boolean;
	reservationPriorityId: number;
	displayName: string;
	value: number;
	color: string;
	handleClose?: () => void;
	handleEdited?: () => void;
}

const CreateReservationPriorityDialog: React.FC<IEditReservationPriorityDialogProps> = function(
	props: IEditReservationPriorityDialogProps,
) {
	const {
		fullScreen,
		open,
		handleClose,
		classes,
		reservationPriorityId,
		handleEdited,
		displayName,
		value,
		color,
	} = props;

	return (
		<Mutation<editReservationPriority, editReservationPriorityVariables> mutation={EDIT_RESERVATION_PRIORITY}>
			{(createReservationPriority, { error }) => {
				return (
					<Formik
						initialValues={{ displayName, color, value }}
						validate={values => {
							return validate(values, schema);
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const result = await createReservationPriority({
									variables: {
										...values,
										reservationPriorityId,
										value: Number(values.value),
									},
								});
								if (result != null && result.data != null && result.data.editReservationPriority != null) {
									setSubmitting(false);
									if (handleEdited) {
										handleEdited();
									}
									if (handleClose) {
										handleClose();
									}
								} else {
									setSubmitting(false);
								}
							} catch {
								setSubmitting(false);
							}
						}}
						render={props => (
							<Dialog
								fullScreen={fullScreen}
								open={open}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">Priorität editieren</DialogTitle>
								<DialogContent>
									<DialogContentText>Editiere eine Priorität</DialogContentText>
									<Form>
										<Field
											fullWidth
											className={classes.field}
											name="displayName"
											label="Anzeigename"
											variant="outlined"
											component={TextField}
										/>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Field
													fullWidth
													className={classes.field}
													name="color"
													label="Farbe"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
											<Grid item xs={6}>
												<Field
													fullWidth
													className={classes.field}
													name="value"
													label="Wert"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
										</Grid>
									</Form>
									{error && <SnackbarContent message={error.message} />}
								</DialogContent>
								{props.isSubmitting && <LinearProgress />}
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Abbrechen
									</Button>
									<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
										Priorität editieren
									</Button>
								</DialogActions>
							</Dialog>
						)}
					/>
				);
			}}
		</Mutation>
	);
};

export default withMobileDialog()(withStyles(styles)(CreateReservationPriorityDialog));
