import { createStyles, Theme } from '@material-ui/core';

const styles = ({ spacing }: Theme) => {
	return createStyles({
		field: {
			marginBottom: spacing(2),
		},
	});
};

export default styles;
