import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints }: Theme) => {
	return createStyles({
		content: {
			padding: spacing(1),
		},
		row: {
			display: 'flex',
			alignItems: 'center',
		},
		spacer: {
			flexGrow: 1,
		},
		fullWidthField: {
			marginTop: '10px',
			marginBottom: '5px',
			width: '100%',
		},
		avatar: {
			height: '100px',
			width: '100px',
		},
		portlet: {
			[breakpoints.down('sm')]: {
				borderRadius: 0,
			},
		},
	});
};

export default styles;
