import React, { Component, Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { IconButton, Toolbar, Typography } from '@material-ui/core';

// Material icons
import { Menu as MenuIcon, ClearAll as CloseIcon, Input as InputIcon } from '@material-ui/icons';

// Custom components
import { NotificationIconTab, UserIconTab } from 'components';

// Component styles
import styles from './styles';

interface ITopbarProps extends WithStyles<typeof styles>, RouteComponentProps {
	className: string;
	isSidebarOpen: boolean;
	onToggleSidebar: () => void;
	title: string;
}

class Topbar extends Component<ITopbarProps, {}> {
	static defaultProps = {
		onToggleSidebar: () => {},
	};

	handleSignOut = () => {
		const { history } = this.props;
		history.push('/sign-in');
	};

	render() {
		const { classes, className, title, isSidebarOpen, onToggleSidebar } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Fragment>
				<div className={rootClassName}>
					<Toolbar className={classes.toolbar}>
						<IconButton className={classes.menuButton} onClick={onToggleSidebar}>
							{isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
						</IconButton>
						<Typography className={classes.title} variant="h4">
							{title}
						</Typography>
						<NotificationIconTab className={classes.notificationsButton} />
						<UserIconTab />
						<IconButton className={classes.signOutButton} onClick={this.handleSignOut}>
							<InputIcon />
						</IconButton>
					</Toolbar>
				</div>
			</Fragment>
		);
	}
}

export default withStyles(styles)(withRouter<ITopbarProps>(Topbar));
