import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
	Grid,
	MenuItem,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { CreateSeatRow, CreateSeatRowVariables } from '__schema__/CreateSeatRow';
import { FillFrom } from '__schema__/globalTypes';

const CREATE_SEATROW = gql`
	mutation CreateSeatRow(
		$audienceSpaceId: Int!
		$displayName: String!
		$seatCount: Int!
		$fillFrom: FillFrom!
	) {
		createSeatRow(
			createSeatRowData: {
				audienceSpaceId: $audienceSpaceId
				displayName: $displayName
				seatCount: $seatCount
				fillFrom: $fillFrom
			}
		) {
			id
			disabled
			seats {
				id
				disabled
			}
			displayName
		}
	}
`;

interface ICreateSeatRowDialogProps extends WithMobileDialog, WithStyles<typeof styles> {
	open: boolean;
	audienceSpaceId: number;
	handleClose?: () => void;
	handleCreated?: () => void;
}

const CreateSeatRowDialog: React.FC<ICreateSeatRowDialogProps> = function(props: ICreateSeatRowDialogProps) {
	const { fullScreen, open, handleClose, classes, audienceSpaceId, handleCreated } = props;

	return (
		<Mutation<CreateSeatRow, CreateSeatRowVariables> mutation={CREATE_SEATROW}>
			{(createSeatRow, { error }) => {
				return (
					<Formik
						initialValues={{ displayName: '', seatCount: 5, fillFrom: FillFrom.Left }}
						validate={values => {
							return validate(values, schema);
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const result = await createSeatRow({
									variables: {
										...values,
										audienceSpaceId: audienceSpaceId,
										seatCount: Number(values.seatCount),
									},
								});
								if (result != null && result.data != null && result.data.createSeatRow != null) {
									setSubmitting(false);
									if (handleCreated) {
										handleCreated();
									}
									if (handleClose) {
										handleClose();
									}
								} else {
									setSubmitting(false);
								}
							} catch {
								setSubmitting(false);
							}
						}}
						render={props => (
							<Dialog
								fullScreen={fullScreen}
								open={open}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">Neue Sitzreihe erstellen</DialogTitle>
								<DialogContent>
									<DialogContentText>Erstelle eine Sitzreihe</DialogContentText>
									<Form>
										<Field
											className={classes.fullWidthField}
											name="displayName"
											label="Anzeigename"
											variant="outlined"
											component={TextField}
										/>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Field
													className={classes.fullWidthField}
													name="fillFrom"
													label="Auffüllen von"
													select
													variant="outlined"
													component={TextField}
												>
													<MenuItem value={FillFrom.Right}>Rechts</MenuItem>
													<MenuItem value={FillFrom.Left}>Links</MenuItem>
													<MenuItem value={FillFrom.Center}>Mitte</MenuItem>
												</Field>
											</Grid>
											<Grid item xs={6}>
												<Field
													className={classes.fullWidthField}
													name="seatCount"
													label="Sitzanzahl"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
										</Grid>
									</Form>
									{error && <SnackbarContent message={error.message} />}
								</DialogContent>
								{props.isSubmitting && <LinearProgress />}
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Abbrechen
									</Button>
									<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
										Sitzreihe erstellen
									</Button>
								</DialogActions>
							</Dialog>
						)}
					/>
				);
			}}
		</Mutation>
	);
};

export default withMobileDialog()(withStyles(styles)(CreateSeatRowDialog));
