export function isAuthenticated(): boolean {
	const token = window.localStorage.getItem('token');
	if (!(token == null)) {
		try {
			const expiresAt: Date = new Date(
				Number(window.localStorage.getItem('tokenExpiresAt')),
			);
			if (expiresAt.valueOf() > Date.now().valueOf()) {
				return true;
			}
		} catch {
			return false;
		}
	}
	return false;
}
