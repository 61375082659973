import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints, palette }: Theme) => {
	return createStyles({
		toolbar: {
			borderBottomWidth: '1px',
			borderBottomColor: '#DFE3E8',
			borderBottomStyle: 'solid',
			padding: spacing(0.5),
		},
		spacer: {
			flexGrow: 1,
		},
	});
};

export default styles;
