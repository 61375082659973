import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { IconButton, Toolbar } from '@material-ui/core';

// Material icons
import { Input as InputIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';
import { UserIconTab } from 'components';

interface ITopbarProps extends WithStyles<typeof styles>, RouteComponentProps {
	className: string;
}

class Topbar extends Component<ITopbarProps, {}> {
	handleSignOut = () => {
		const { history } = this.props;
		history.push('/sign-in');
	};

	render() {
		const { classes, className } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Fragment>
				<div className={rootClassName}>
					<Toolbar className={classes.toolbar}>
						<img className={classes.logo} alt={'TicketOS'} src="/images/logos/logo.png" />
						<UserIconTab className={classes.notificationsButton} />
						<IconButton className={classes.signOutButton} onClick={this.handleSignOut}>
							<InputIcon />
						</IconButton>
					</Toolbar>
				</div>
			</Fragment>
		);
	}
}

export default withStyles(styles)(withRouter<ITopbarProps>(Topbar));
