export default {
	displayName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
	beginsAt: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
	},
	maxTicketCount: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: -2,
			lessThan: 20000,
		},
	},
};
