import React, { Component } from 'react';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { getAudienceSpaceVariables, getAudienceSpace } from '__schema__/getAudienceSpace';
import { AudienceSpaceView } from 'components';
import { ISeatRow } from 'components/AudienceSpaceView';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Grid, Button, withStyles, WithStyles, Tooltip } from '@material-ui/core';
import styles from './styles';
import CreateSeatRowDialog from './components/CreateSeatRowDialog';
import { moveSeatRow, moveSeatRowVariables } from '__schema__/moveSeatRow';
import {
	Add,
	RotateLeftOutlined,
	RotateRightOutlined,
	RemoveCircleOutline,
	Remove,
	CancelOutlined,
	DeleteOutline,
	FormatAlignLeftOutlined,
	FormatAlignCenterOutlined,
	FormatAlignRightOutlined,
} from '@material-ui/icons';
import { rotateSeatRow, rotateSeatRowVariables } from '__schema__/rotateSeatRow';
import { rotation } from '__schema__/rotation';
import { position } from '__schema__/position';
import { addSeatsToSeatRow, addSeatsToSeatRowVariables } from '__schema__/addSeatsToSeatRow';
import { removeSeatsFromSeatRow, removeSeatsFromSeatRowVariables } from '__schema__/removeSeatsFromSeatRow';
import {
	changeSeatRowDisabledById,
	changeSeatRowDisabledByIdVariables,
} from '__schema__/changeSeatRowDisabledById';
import { changeSeatDisabledById, changeSeatDisabledByIdVariables } from '__schema__/changeSeatDisabledById';
import { disabledSeat } from '__schema__/disabledSeat';
import { disabledSeatRow } from '__schema__/disabledSeatRow';
import { deleteSeatRow, deleteSeatRowVariables } from '__schema__/deleteSeatRow';
import { FillFrom } from '__schema__/globalTypes';
import { priorityAndFillFrom } from '__schema__/priorityAndFillFrom';
import { setSeatRowFillFromById, setSeatRowFillFromByIdVariables } from '__schema__/setSeatRowFillFromById';
import { setSeatRowPriorityByIdVariables, setSeatRowPriorityById } from '__schema__/setSeatRowPriorityById';
import SetPriorityDialog from './components/SetPriorityDialog';
import { fillFrom } from '__schema__/fillFrom';
import { priority } from '__schema__/priority';

const GET_AUDIENCE_SPACE = gql`
	query getAudienceSpace($id: Int!) {
		getAudienceSpaceById(id: $id) {
			id
			sizeX
			sizeY
			seatRows {
				id
				disabled
				x
				y
				rotation
				displayName
				fillFrom
				priority
				seats {
					id
					index
					disabled
				}
			}
		}
	}
`;

const MOVE_SEATROW = gql`
	mutation moveSeatRow($id: Int!, $x: Int!, $y: Int!) {
		moveSeatRowById(id: $id, x: $x, y: $y) {
			id
			x
			y
		}
	}
`;

const ROTATE_SEATROW = gql`
	mutation rotateSeatRow($id: Int!, $rotation: Int!) {
		rotateSeatRowById(id: $id, rotation: $rotation) {
			id
			rotation
		}
	}
`;

const ADD_SEATS_TO_SEATROW = gql`
	mutation addSeatsToSeatRow($id: Int!, $seatCount: Int!) {
		addSeatsToSeatRowById(id: $id, seatCount: $seatCount) {
			id
			x
			y
			seats {
				id
				index
				disabled
			}
		}
	}
`;

const REMOVE_SEATS_FROM_SEATROW = gql`
	mutation removeSeatsFromSeatRow($id: Int!, $seatCount: Int!) {
		removeSeatsFromSeatRowById(id: $id, seatCount: $seatCount) {
			id
			x
			y
			seats {
				id
				index
				disabled
			}
		}
	}
`;

const SET_DISABLE_SEATROW = gql`
	mutation changeSeatRowDisabledById($id: Int!, $disabled: Boolean!) {
		changeSeatRowDisabledById(disabled: $disabled, id: $id) {
			id
			disabled
		}
	}
`;

const SET_DISABLE_SEAT = gql`
	mutation changeSeatDisabledById($id: Int!, $disabled: Boolean!) {
		changeSeatDisabledById(disabled: $disabled, id: $id) {
			id
			disabled
		}
	}
`;

const SET_FILLFROM_SEATROW = gql`
	mutation setSeatRowFillFromById($id: Int!, $fillFrom: FillFrom!) {
		setSeatRowFillFromById(id: $id, fillFrom: $fillFrom) {
			id
			fillFrom
		}
	}
`;

const SET_PRIORITY_SEATROW = gql`
	mutation setSeatRowPriorityById($id: Int!, $priority: Int!) {
		setSeatRowPriorityById(id: $id, priority: $priority) {
			id
			priority
		}
	}
`;

const DELETE_SEATROW = gql`
	mutation deleteSeatRow($id: Int!) {
		deleteSeatRowById(id: $id) {
			id
			seatRows {
				id
			}
		}
	}
`;

interface IEditAudienceSpaceProps extends WithStyles<typeof styles>, WithSnackbarProps {
	audienceSpaceId: number;
}

//eslint-disable-next-line
interface props extends WithApolloClient<IEditAudienceSpaceProps> {}

interface IEditAudienceSpaceState {
	createSeatRowDialogOpen: boolean;
	setPriorityDialogOpen: boolean;
	selectedRows: number[];
	selectedSeats: number[];
	currentPriority: number | null;
	currentFillFrom: FillFrom | null;
}

class EditAudienceSpace extends Component<props, IEditAudienceSpaceState> {
	state = {
		createSeatRowDialogOpen: false,
		setPriorityDialogOpen: false,
		selectedRows: [] as number[],
		selectedSeats: [] as number[],
		currentPriority: null,
		currentFillFrom: null,
	};

	openCreateSeatRowDialog = () => {
		this.setState({
			createSeatRowDialogOpen: true,
		});
	};

	closeCreateSeatRowSpaceDialog = () => {
		this.setState({
			createSeatRowDialogOpen: false,
		});
	};

	openSetPriorityDialog = () => {
		this.setState({
			setPriorityDialogOpen: true,
		});
	};

	closeSetPriorityDialog = () => {
		this.setState({
			setPriorityDialogOpen: false,
		});
	};

	sendMutationMoveSeatRow = async (id: number, x: number, y: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<moveSeatRow, moveSeatRowVariables>({
				mutation: MOVE_SEATROW,
				variables: { id, x: Math.round(x), y: Math.round(y) },
				optimisticResponse: {
					moveSeatRowById: {
						id: id,
						__typename: 'SeatRow',
						x: Math.round(x),
						y: Math.round(y),
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim Verschieben der Sitzreihe', { variant: 'error' });
		}
	};

	sendMutationAddSeatsToSeatRow = async (id: number, seatCount: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<addSeatsToSeatRow, addSeatsToSeatRowVariables>({
				mutation: ADD_SEATS_TO_SEATROW,
				variables: { id, seatCount },
			});
		} catch {
			enqueueSnackbar('Fehler beim Hinzufügen von Sitzen zur Sitzreihe', { variant: 'error' });
		}
	};

	sendMutationRemoveSeatsFromSeatRow = async (id: number, seatCount: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<removeSeatsFromSeatRow, removeSeatsFromSeatRowVariables>({
				mutation: REMOVE_SEATS_FROM_SEATROW,
				variables: { id, seatCount },
			});
		} catch {
			enqueueSnackbar('Fehler beim Entfernen von Sitzen von der Sitzreihe', { variant: 'error' });
		}
	};

	handleAddSeatsToSeatRow = async (seatCount: number) => {
		const { selectedRows } = this.state;
		selectedRows.forEach(seatRowId => {
			this.sendMutationAddSeatsToSeatRow(seatRowId, seatCount);
		});
	};

	handleRemoveSeatsFromSeatRow = async (seatCount: number) => {
		const { selectedRows } = this.state;
		selectedRows.forEach(seatRowId => {
			this.sendMutationRemoveSeatsFromSeatRow(seatRowId, seatCount);
		});
	};

	handleSeatRowMoved = async (id: number, x: number, y: number) => {
		const { client } = this.props;
		const { selectedRows } = this.state;
		if (selectedRows.length === 0 || (selectedRows.length === 1 && selectedRows.includes(id))) {
			this.sendMutationMoveSeatRow(id, x, y);
		} else {
			const seatRow = client.readFragment<position>({
				id: `SeatRow:${id}`,
				fragment: gql`
					fragment position on SeatRow {
						x
						y
					}
				`,
			});
			if (seatRow != null) {
				const deltaX = x - seatRow.x;
				const deltaY = y - seatRow.y;
				selectedRows.forEach(seatRowId => {
					const targetSeatRow = client.readFragment<position>({
						id: `SeatRow:${seatRowId}`,
						fragment: gql`
							fragment position on SeatRow {
								x
								y
							}
						`,
					});
					if (targetSeatRow != null) {
						this.sendMutationMoveSeatRow(seatRowId, targetSeatRow.x + deltaX, targetSeatRow.y + deltaY);
					}
				});
			}
		}
	};

	sendMutationRotateSeatRow = async (id: number, rotation: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<rotateSeatRow, rotateSeatRowVariables>({
				mutation: ROTATE_SEATROW,
				variables: { id, rotation },
				optimisticResponse: {
					rotateSeatRowById: {
						id: id,
						__typename: 'SeatRow',
						rotation: rotation,
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim Rotieren der Sitzreihe', { variant: 'error' });
		}
	};

	handleRotateSeatRow = async (value: number) => {
		const { client } = this.props;
		const { selectedRows } = this.state;
		selectedRows.forEach(seatRowId => {
			const seatRow = client.readFragment<rotation>({
				id: `SeatRow:${seatRowId}`,
				fragment: gql`
					fragment rotation on SeatRow {
						rotation
					}
				`,
			});
			if (seatRow != null) {
				const rotation = seatRow.rotation + value;
				this.sendMutationRotateSeatRow(seatRowId, rotation);
			}
		});
	};

	sendMutationSetFillFromSeatRow = async (id: number, fillFrom: FillFrom) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<setSeatRowFillFromById, setSeatRowFillFromByIdVariables>({
				mutation: SET_FILLFROM_SEATROW,
				variables: { id, fillFrom },
				optimisticResponse: {
					setSeatRowFillFromById: {
						id: id,
						__typename: 'SeatRow',
						fillFrom: fillFrom,
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim Ändern der Füllrichtung der Sitzreihe', { variant: 'error' });
		}
	};

	handleSetFillFromSeatRow = async (fillFromData: FillFrom): Promise<boolean> => {
		const { client } = this.props;
		const { selectedRows } = this.state;
		const mutations = selectedRows.map(seatRowId => {
			const seatRow = client.readFragment<fillFrom>({
				id: `SeatRow:${seatRowId}`,
				fragment: gql`
					fragment fillFrom on SeatRow {
						fillFrom
					}
				`,
			});
			if (seatRow != null && seatRow.fillFrom !== fillFromData) {
				return this.sendMutationSetFillFromSeatRow(seatRowId, fillFromData);
			}
			return null;
		});
		await Promise.all([mutations]);
		return true;
	};

	sendMutationSetPrioritySeatRow = async (id: number, priority: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<setSeatRowPriorityById, setSeatRowPriorityByIdVariables>({
				mutation: SET_PRIORITY_SEATROW,
				variables: { id, priority },
				optimisticResponse: {
					setSeatRowPriorityById: {
						id: id,
						__typename: 'SeatRow',
						priority: priority,
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim Ändern der Priorität der Sitzreihe', { variant: 'error' });
		}
	};

	handleSetPrioritySeatRow = async (priorityData: number): Promise<boolean> => {
		const { client } = this.props;
		const { selectedRows } = this.state;
		const mutations = selectedRows.map(seatRowId => {
			const seatRow = client.readFragment<priority>({
				id: `SeatRow:${seatRowId}`,
				fragment: gql`
					fragment priority on SeatRow {
						priority
					}
				`,
			});
			if (seatRow != null && seatRow.priority !== priorityData) {
				return this.sendMutationSetPrioritySeatRow(seatRowId, priorityData);
			}
			return null;
		});
		await Promise.all([mutations]);
		return true;
	};

	handleConfirmSetPrioritySeatRow = async (priority: number): Promise<boolean> => {
		const { selectedRows } = this.state;
		await this.handleSetPrioritySeatRow(priority);
		setTimeout(() => {
			this.updateToolBarState(selectedRows);
		}, 500);
		return true;
	};

	sendMutationSetDisableSeatRow = async (id: number, disabled: boolean) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<changeSeatRowDisabledById, changeSeatRowDisabledByIdVariables>({
				mutation: SET_DISABLE_SEATROW,
				variables: { id, disabled },
				optimisticResponse: {
					changeSeatRowDisabledById: {
						id: id,
						__typename: 'SeatRow',
						disabled: disabled,
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim de-/aktivieren der Sitzreihe', { variant: 'error' });
		}
	};

	sendMutationSetDisableSeat = async (id: number, disabled: boolean) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<changeSeatDisabledById, changeSeatDisabledByIdVariables>({
				mutation: SET_DISABLE_SEAT,
				variables: { id, disabled },
				optimisticResponse: {
					changeSeatDisabledById: {
						id: id,
						__typename: 'Seat',
						disabled: disabled,
					},
				},
			});
		} catch {
			enqueueSnackbar('Fehler beim de-/aktivieren des Sitzes', { variant: 'error' });
		}
	};

	handleDisableToggle = async () => {
		const { client } = this.props;
		const { selectedRows, selectedSeats } = this.state;
		selectedRows.forEach(seatRowId => {
			const seatRow = client.readFragment<disabledSeatRow>({
				id: `SeatRow:${seatRowId}`,
				fragment: gql`
					fragment disabledSeatRow on SeatRow {
						disabled
					}
				`,
			});
			if (seatRow != null) {
				this.sendMutationSetDisableSeatRow(seatRowId, !seatRow.disabled);
			}
		});
		selectedSeats.forEach(seatId => {
			const seat = client.readFragment<disabledSeat>({
				id: `Seat:${seatId}`,
				fragment: gql`
					fragment disabledSeat on Seat {
						disabled
					}
				`,
			});
			if (seat != null) {
				this.sendMutationSetDisableSeat(seatId, !seat.disabled);
			}
		});
	};

	sendMutationDeleteSeatRow = async (id: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<deleteSeatRow, deleteSeatRowVariables>({
				mutation: DELETE_SEATROW,
				variables: { id },
			});
			await this.handleClearSelection();
		} catch {
			enqueueSnackbar('Fehler beim Löschen der Sitzreihe', { variant: 'error' });
		}
	};

	handleDeleteSeatRow = async () => {
		const { selectedRows } = this.state;
		selectedRows.forEach(seatRowId => {
			this.sendMutationDeleteSeatRow(seatRowId);
		});
	};

	handleSeatClicked = async (id: number) => {
		const { selectedSeats } = this.state;
		if (selectedSeats.includes(id)) {
			this.setState({
				selectedSeats: selectedSeats.filter(seatId => seatId !== id),
			});
		} else {
			this.setState({
				selectedSeats: selectedSeats.concat([id]),
			});
		}
	};

	handleSeatDoubleClicked = async (id: number) => {
		this.setState({
			selectedSeats: [id],
		});
	};

	handleClearSelection = () => {
		this.setState({
			selectedSeats: [],
			selectedRows: [],
		});
		this.updateToolBarState([]);
	};

	handleSeatRowClicked = async (id: number) => {
		const { selectedRows } = this.state;
		let newSelectedRows: number[];
		if (selectedRows.includes(id)) {
			newSelectedRows = selectedRows.filter(seatId => seatId !== id);
		} else {
			newSelectedRows = selectedRows.concat([id]);
		}
		this.setState({
			selectedRows: newSelectedRows,
		});
		this.updateToolBarState(newSelectedRows);
	};

	handleSeatRowDoubleClicked = async (id: number) => {
		this.setState({
			selectedRows: [id],
		});
		this.updateToolBarState([id]);
	};

	updateToolBarState = async (selectedRows: number[]) => {
		const { client } = this.props;
		const currentSeatRowState = selectedRows.map(seatRowId => {
			return client.readFragment<priorityAndFillFrom>({
				id: `SeatRow:${seatRowId}`,
				fragment: gql`
					fragment priorityAndFillFrom on SeatRow {
						id
						fillFrom
						priority
					}
				`,
			});
		});
		let newPriority: number | null = null;
		let newFillFrom: FillFrom | null = null;
		currentSeatRowState.forEach((seatRowState, index) => {
			if (seatRowState != null) {
				if (index === 0) {
					newPriority = seatRowState.priority;
					newFillFrom = seatRowState.fillFrom;
				} else {
					if (newPriority !== seatRowState.priority) {
						newPriority = null;
					}
					if (newFillFrom !== seatRowState.fillFrom) {
						newFillFrom = null;
					}
				}
			}
		});
		this.setState({
			currentPriority: newPriority,
			currentFillFrom: newFillFrom,
		});
	};

	render() {
		const { audienceSpaceId, classes } = this.props;
		const {
			createSeatRowDialogOpen,
			selectedSeats,
			selectedRows,
			currentFillFrom,
			currentPriority,
			setPriorityDialogOpen,
		} = this.state;
		return (
			<Query<getAudienceSpace, getAudienceSpaceVariables>
				query={GET_AUDIENCE_SPACE}
				variables={{ id: audienceSpaceId }}
			>
				{({ data, refetch }) => {
					if (data != null && data.getAudienceSpaceById != null) {
						const seatRowData: ISeatRow[] = data.getAudienceSpaceById.seatRows.map(seatRow => ({
							id: seatRow.id,
							displayName: seatRow.displayName,
							x: seatRow.x,
							y: seatRow.y,
							rotation: seatRow.rotation,
							fillFrom: seatRow.fillFrom.toLowerCase() as ('center' | 'left' | 'right'),
							disabled: seatRow.disabled,
							selected: selectedRows.includes(seatRow.id),
							seats: seatRow.seats.map(seat => ({
								...seat,
								activated: selectedSeats.includes(seat.id),
								taken: false,
							})),
						}));
						return (
							<Grid container>
								<Grid className={classes.toolbar} item xs={12}>
									<Grid container justify="space-between">
										<Grid item>
											<Button color="primary" onClick={this.openCreateSeatRowDialog}>
												<Add /> Sitzreihe erstellen
											</Button>
											<CreateSeatRowDialog
												open={createSeatRowDialogOpen}
												audienceSpaceId={audienceSpaceId}
												handleClose={this.closeCreateSeatRowSpaceDialog}
												handleCreated={refetch}
											/>
										</Grid>
										<Grid item>
											<Grid container justify="center" direction="row">
												<Grid item>
													<Tooltip title="Rotiere Sitzreihe links">
														<Button
															color="primary"
															onClick={() => {
																this.handleRotateSeatRow(-10);
															}}
														>
															<RotateLeftOutlined />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Rotiere Sitzreihe rechts">
														<Button
															color="primary"
															onClick={() => {
																this.handleRotateSeatRow(10);
															}}
														>
															<RotateRightOutlined />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="De-/Aktiviere Sitz bzw. Sitzreihe">
														<Button
															color="primary"
															onClick={() => {
																this.handleDisableToggle();
															}}
														>
															<RemoveCircleOutline />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Sitz zu Sitzreihe hinzufügen">
														<Button
															color="primary"
															onClick={() => {
																this.handleAddSeatsToSeatRow(1);
															}}
														>
															<Add />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Sitz der Sitzreihe entfernen">
														<Button
															color="primary"
															onClick={() => {
																this.handleRemoveSeatsFromSeatRow(1);
															}}
														>
															<Remove />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Sitzreihe löschen">
														<Button
															color="primary"
															onClick={() => {
																this.handleDeleteSeatRow();
															}}
														>
															<DeleteOutline />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Verändere die Priorität">
														<Button
															color="primary"
															onClick={() => {
																this.openSetPriorityDialog();
															}}
														>
															Priorität: {currentPriority != null ? currentPriority : '?'}
														</Button>
													</Tooltip>
													<SetPriorityDialog
														key={selectedRows.toString()}
														open={setPriorityDialogOpen}
														initialPriority={currentPriority != null ? Number(currentPriority) : -1}
														handleClose={this.closeSetPriorityDialog}
														handleConfirm={this.handleConfirmSetPrioritySeatRow}
													/>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Grid container justify="center" direction="row">
												<Grid item>
													<Tooltip title="Sitzreihe von Links auffüllen">
														<Button
															disabled={currentFillFrom === FillFrom.Left}
															color="primary"
															onClick={async () => {
																await this.handleSetFillFromSeatRow(FillFrom.Left);
																setTimeout(() => {
																	this.updateToolBarState(selectedRows);
																}, 500);
															}}
														>
															<FormatAlignLeftOutlined />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Sitzreihe von der Mitte auffüllen">
														<Button
															disabled={currentFillFrom === FillFrom.Center}
															color="primary"
															onClick={async () => {
																await this.handleSetFillFromSeatRow(FillFrom.Center);
																setTimeout(() => {
																	this.updateToolBarState(selectedRows);
																}, 500);
															}}
														>
															<FormatAlignCenterOutlined />
														</Button>
													</Tooltip>
												</Grid>
												<Grid item>
													<Tooltip title="Sitzreihe von Rechts auffüllen">
														<Button
															disabled={currentFillFrom === FillFrom.Right}
															color="primary"
															onClick={async () => {
																await this.handleSetFillFromSeatRow(FillFrom.Right);
																setTimeout(() => {
																	this.updateToolBarState(selectedRows);
																}, 500);
															}}
														>
															<FormatAlignRightOutlined />
														</Button>
													</Tooltip>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Tooltip title="Selektion aufheben">
												<Button color="primary" onClick={this.handleClearSelection}>
													<CancelOutlined />
												</Button>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<AudienceSpaceView
										key={data.getAudienceSpaceById.id}
										sizeX={data.getAudienceSpaceById.sizeX}
										sizeY={data.getAudienceSpaceById.sizeY}
										seatRowData={seatRowData}
										seatRowsDraggable
										seatRowMoved={this.handleSeatRowMoved}
										seatClicked={this.handleSeatClicked}
										seatDoubleClicked={this.handleSeatDoubleClicked}
										seatRowClicked={this.handleSeatRowClicked}
										seatRowDoubleClicked={this.handleSeatRowDoubleClicked}
									/>
								</Grid>
							</Grid>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	}
}

export default withSnackbar(withStyles(styles)(withApollo<IEditAudienceSpaceProps>(EditAudienceSpace)));
