import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints }: Theme) => {
	return createStyles({
		root: {
			padding: spacing(3),
			[breakpoints.down('sm')]: {
				padding: 0,
			},
		},
		tabs: {
			marginBottom: spacing(2),
			[breakpoints.down('sm')]: {
				marginBottom: 0,
				borderRadius: 0,
			},
		},
		content: {
			marginTop: spacing(2),
		},
	});
};

export default styles;
