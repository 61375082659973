import React, { Component } from 'react';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { AudienceSpaceView, LoadingView } from 'components';
import { ISeatRow } from 'components/AudienceSpaceView';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Grid, withStyles, WithStyles, Paper } from '@material-ui/core';
import styles from './styles';
import {
	getReservationAudienceSpaceView,
	getReservationAudienceSpaceViewVariables,
} from '__schema__/getReservationAudienceSpaceView';

const GET_RESERVATOON_AUDIENCESPACE_TICKET_VIEW = gql`
	query getReservationAudienceSpaceView($id: Int!) {
		getReservationById(id: $id) {
			id
			tickets {
				id
				seat {
					id
				}
			}
			show {
				audienceSpace {
					id
					sizeX
					sizeY
					seatRows {
						id
						disabled
						x
						y
						rotation
						displayName
						fillFrom
						seats {
							id
							index
						}
					}
				}
			}
		}
	}
`;

interface IReservationAudienceSpaceViewProps extends WithStyles<typeof styles>, WithSnackbarProps {
	reservationId: number;
}

//eslint-disable-next-line
interface props extends WithApolloClient<IReservationAudienceSpaceViewProps> {}

class ReservationAudienceSpaceView extends Component<props, {}> {
	render() {
		const { reservationId, classes } = this.props;

		return (
			<Query<getReservationAudienceSpaceView, getReservationAudienceSpaceViewVariables>
				query={GET_RESERVATOON_AUDIENCESPACE_TICKET_VIEW}
				variables={{ id: reservationId }}
			>
				{({ data }) => {
					if (data != null && data.getReservationById != null) {
						const { audienceSpace } = data.getReservationById.show;

						const highlightedSeats = data.getReservationById.tickets.map(ticket => ticket.seat.id);

						const seatRowData: ISeatRow[] = this.computeSeatRowData(data, highlightedSeats);

						return (
							<div className={classes.root}>
								<Paper>
									<Grid container>
										<Grid item xs={12}>
											<AudienceSpaceView
												key={audienceSpace.id}
												sizeX={audienceSpace.sizeX}
												sizeY={audienceSpace.sizeY}
												seatRowData={seatRowData}
											/>
										</Grid>
									</Grid>
								</Paper>
							</div>
						);
					} else {
						return <LoadingView />;
					}
				}}
			</Query>
		);
	}

	private computeSeatRowData(data: getReservationAudienceSpaceView, highlightedSeats: number[]): ISeatRow[] {
		return data.getReservationById.show.audienceSpace.seatRows.map(seatRow => ({
			id: seatRow.id,
			displayName: seatRow.displayName,
			x: seatRow.x,
			y: seatRow.y,
			rotation: seatRow.rotation,
			fillFrom: seatRow.fillFrom.toLowerCase() as ('center' | 'left' | 'right'),
			disabled: seatRow.disabled,
			selected: false,
			seats: seatRow.seats.map(seat => ({
				...seat,
				activated: false,
				highlighted: highlightedSeats.includes(seat.id),
				taken: false,
			})),
		}));
	}
}

export default withSnackbar(
	withStyles(styles)(withApollo<IReservationAudienceSpaceViewProps>(ReservationAudienceSpaceView)),
);
