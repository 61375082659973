import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => {
	return createStyles({
		root: {
			borderBottom: '1px solid #DFE3E8',
			backgroundColor: theme.palette.common.white,
			display: 'flex',
			alignItems: 'center',
			height: '64px',
			zIndex: theme.zIndex.appBar,
		},
		toolbar: {
			minHeight: 'auto',
			width: '100%',
		},
		title: {
			marginLeft: theme.spacing(1),
		},
		menuButton: {
			marginLeft: '-4px',
		},
		notificationsButton: {
			marginLeft: 'auto',
		},
		signOutButton: {
			marginLeft: theme.spacing(1),
		},
	});
};

export default styles;
