export default {
	displayName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
	color: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
	value: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
			lessThanOrEqual: 2000,
		},
	},
};
