import React, { Component } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@material-ui/core';
import styles from './styles';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { Add } from '@material-ui/icons';
import { getProjectPrioritiesVariables, getProjectPriorities } from '__schema__/getProjectPriorities';
import { CreateReservationPriorityDialog, EditReservationPriorityDialog } from './components';
import ReservationPriorityRow from './components/ReservationPriorityRow';

const GET_PROJECT_PRIORITIES = gql`
	query getProjectPriorities($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			reservationPriorities {
				id
				displayName
				value
				color
				createdAt
			}
		}
	}
`;

interface IReservationPrioritySettingsProps extends WithStyles<typeof styles>, WithSnackbarProps {
	projectIdentifier: string;
}

interface IReservationPrioritySettingsState {
	createDialogOpen: boolean;
	editDialogOpen: boolean;
	editReservationPriorityId: number;
	initialDisplayName: string;
	initialValue: number;
	initialColor: string;
}

class MemberSettings extends Component<
	WithApolloClient<IReservationPrioritySettingsProps>,
	IReservationPrioritySettingsState
> {
	state = {
		createDialogOpen: false,
		editDialogOpen: false,
		initialDisplayName: '',
		initialValue: 10,
		initialColor: '#ffffff',
		editReservationPriorityId: -1,
	};

	openCreateDialog = () => {
		this.setState({
			createDialogOpen: true,
		});
	};

	closeCreateDialog = () => {
		this.setState({
			createDialogOpen: false,
		});
	};

	closeEditDialog = () => {
		this.setState({
			editDialogOpen: false,
		});
	};

	render() {
		const { classes, projectIdentifier } = this.props;
		const {
			createDialogOpen,
			editDialogOpen,
			editReservationPriorityId,
			initialColor,
			initialDisplayName,
			initialValue,
		} = this.state;
		return (
			<Query<getProjectPriorities, getProjectPrioritiesVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_PRIORITIES}
			>
				{({ data, refetch }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<Portlet className={classes.portlet}>
								<CreateReservationPriorityDialog
									projectId={data.getProjectByIdentifier.id}
									open={createDialogOpen}
									handleClose={this.closeCreateDialog}
									handleCreated={refetch}
								/>
								<EditReservationPriorityDialog
									key={editReservationPriorityId}
									reservationPriorityId={editReservationPriorityId}
									open={editDialogOpen}
									color={initialColor}
									displayName={initialDisplayName}
									value={initialValue}
									handleClose={this.closeEditDialog}
									handleEdited={refetch}
								/>
								<PortletHeader>
									<Typography variant="h4">Prioritäten</Typography>
								</PortletHeader>
								<PortletContent noPadding>
									<Grid container>
										<Grid className={classes.toolbar} item xs={12}>
											<Grid spacing={2} container justify="space-between" alignItems="center">
												<Grid item xs={6}>
													<Button color="primary" onClick={this.openCreateDialog}>
														<Add />
														&nbsp;Priorität hinzufügen
													</Button>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<PerfectScrollbar>
												<Table>
													<TableHead>
														<TableRow>
															<TableCell className={classes.avatarCell} align="left" />
															<TableCell align="left">Anzeigename</TableCell>
															<TableCell align="left">ID</TableCell>
															<TableCell align="left">Wert</TableCell>
															<TableCell align="left">Erstellt am</TableCell>
															<TableCell align="left">Actions</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{data.getProjectByIdentifier.reservationPriorities
															.sort((a, b) => a.value - b.value)
															.map(reservationPriority => (
																<ReservationPriorityRow
																	key={reservationPriority.id}
																	id={reservationPriority.id}
																	color={reservationPriority.color}
																	value={reservationPriority.value}
																	displayName={reservationPriority.displayName}
																	createdAt={reservationPriority.createdAt}
																	onEditClick={data => {
																		this.setState({
																			editDialogOpen: true,
																			editReservationPriorityId: data.id,
																			initialColor: data.color,
																			initialValue: data.value,
																			initialDisplayName: data.displayName,
																		});
																	}}
																/>
															))}
													</TableBody>
												</Table>
											</PerfectScrollbar>
										</Grid>
									</Grid>
								</PortletContent>
								<PortletFooter>
									<div className={classes.row}>
										<span className={classes.spacer} />
									</div>
								</PortletFooter>
							</Portlet>
						);
					}
					return null;
				}}
			</Query>
		);
	}
}

export default withSnackbar(
	withStyles(styles)(withApollo<IReservationPrioritySettingsProps>(MemberSettings)),
);
