import React, { Component } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { Button } from '@material-ui/core';

// Shared components
import { DisplayMode, SearchInput } from 'components';

// Component styles
import styles from './styles';

interface IShowToolbarProps extends WithStyles<typeof styles> {
	className?: string;
	onClickAddShow?: () => void;
	onSearchChange?: (value: string) => void;
}

class ShowToolbar extends Component<IShowToolbarProps, {}> {
	render() {
		const { classes, className, onClickAddShow, onSearchChange } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<div className={rootClassName}>
				<div className={classes.row}>
					<span className={classes.spacer} />
					<Button onClick={onClickAddShow} color="primary" size="small" variant="outlined">
						Show hinzufügen
					</Button>
				</div>
				<div className={classes.row}>
					<SearchInput onChange={onSearchChange} />
					<span className={classes.spacer} />
					<DisplayMode mode="grid" />
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(ShowToolbar);
