import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => {
	return createStyles({
		iconButton: {},
		avatar: {
			width: theme.typography.pxToRem(24),
			height: theme.typography.pxToRem(24),
		},
	});
};

export default styles;
