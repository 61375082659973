// React
import React, { Component, Fragment } from 'react';

// MaterialUI
import { withWidth, withStyles } from '@material-ui/core';

// Compose
import { compose } from 'recompose';

// Styles
import styles from './styles';

// Components
import { Topbar, Footer } from './components';
import { WithStyles } from '@material-ui/styles';
import { WithWidth } from '@material-ui/core/withWidth';

interface IProjectProps extends WithStyles<typeof styles>, WithWidth {}

class Project extends Component<IProjectProps, {}> {
	render() {
		const { classes, children } = this.props;

		return (
			<Fragment>
				<Topbar className={classes.topbar} />
				<main className={classes.content}>
					{children}
					<Footer />
				</main>
			</Fragment>
		);
	}
}

export default compose<IProjectProps, {}>(
	withStyles(styles),
	withWidth(),
)(Project);
