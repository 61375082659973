import React, { Component } from 'react';
import { Query, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { AudienceSpaceView, SearchInput, LoadingView } from 'components';
import { ISeatRow } from 'components/AudienceSpaceView';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import {
	Grid,
	Button,
	withStyles,
	WithStyles,
	Tooltip,
	Paper,
	ListItemText,
	ListItem,
	Divider,
	List,
	LinearProgress,
	Chip,
} from '@material-ui/core';
import styles from './styles';
import {
	Add,
	Remove,
	CancelOutlined,
	FlashAutoOutlined,
	RefreshOutlined,
	GroupOutlined,
	PersonOutlined,
	HowToRegOutlined,
	EventSeatOutlined,
} from '@material-ui/icons';
import {
	getShowAudienceSpaceView,
	getShowAudienceSpaceViewVariables,
} from '__schema__/getShowAudienceSpaceView';
import { createTicket, createTicketVariables } from '__schema__/createTicket';
import { seatIds } from '__schema__/seatIds';
import { updateSeat, updateSeatVariables } from '__schema__/updateSeat';
import { updateReservation, updateReservationVariables } from '__schema__/updateReservation';
import { removeTicket, removeTicketVariables } from '__schema__/removeTicket';
import { generateTicketsByShow, generateTicketsByShowVariables } from '__schema__/generateTicketsByShow';
import { fetchTicket, fetchTicketVariables } from '__schema__/fetchTicket';
import { reservationByTicket } from '__schema__/reservationByTicket';

const GET_SHOW_AUDIENCESPACE_TICKET_VIEW = gql`
	query getShowAudienceSpaceView($id: Int!) {
		getShowById(id: $id) {
			id
			reservations {
				id
				seatCount
				firstName
				lastName
				bookingCode
				email
				tickets {
					id
					fetched
					seat {
						index
						id
						displayName
					}
				}
				priority {
					id
					value
					displayName
					color
				}
			}
			audienceSpace {
				id
				sizeX
				sizeY
				seatRows {
					id
					disabled
					x
					y
					rotation
					displayName
					fillFrom
					seats {
						id
						index
						disabled
						displayName
						getTicketByShow(showId: $id) {
							id
							fetched
							reservation {
								id
							}
						}
					}
				}
			}
		}
	}
`;

const CREATE_TICKET = gql`
	mutation createTicket($reservationId: Int!, $seatId: Int!) {
		createTicket(createTicketData: { reservationId: $reservationId, seatId: $seatId }) {
			id
			fetched
		}
	}
`;

const GENERATE_TICKETS_BY_SHOW = gql`
	mutation generateTicketsByShow($showId: Int!) {
		generateTicketsByShow(showId: $showId) {
			id
			fetched
		}
	}
`;

const REMOVE_TICKET = gql`
	mutation removeTicket($ticketId: Int!) {
		removeTicketById(id: $ticketId) {
			id
			tickets {
				id
				fetched
			}
		}
	}
`;

const FETCH_TICKET = gql`
	mutation fetchTicket($ticketId: Int!) {
		fetchTicketById(id: $ticketId) {
			id
			fetched
		}
	}
`;

const UPDATE_SEAT_TICKET = gql`
	query updateSeat($seatId: Int!, $showId: Int!) {
		getSeatById(id: $seatId) {
			id
			displayName
			getTicketByShow(showId: $showId) {
				id
				fetched
			}
		}
	}
`;

const UPDATE_RESERVATION = gql`
	query updateReservation($reservationId: Int!) {
		getReservationById(id: $reservationId) {
			id
			tickets {
				id
				fetched
				seat {
					displayName
					id
				}
			}
		}
	}
`;

interface IShowAudienceSpaceViewProps extends WithStyles<typeof styles>, WithSnackbarProps {
	showId: number;
}

enum Mode {
	Placement = 'placement',
	Fetch = 'fetch',
}

enum RefreshMode {
	Single = 'single',
	Collaboration = 'collaboration',
}

//eslint-disable-next-line
interface props extends WithApolloClient<IShowAudienceSpaceViewProps> {}

interface IShowAudienceSpaceViewState {
	mode: Mode;
	refreshMode: RefreshMode;
	selectedSeats: number[];
	selectedReservation: number | null;
	loading: boolean;
	searchInput: string;
}

class ShowAudienceSpaceView extends Component<props, IShowAudienceSpaceViewState> {
	state = {
		mode: Mode.Fetch,
		refreshMode: RefreshMode.Single,
		searchInput: '',
		selectedSeats: [] as number[],
		selectedReservation: null,
		loading: false,
	};

	handleSeatClicked = async (id: number) => {
		const { selectedSeats } = this.state;
		if (selectedSeats.includes(id)) {
			this.setState({
				selectedSeats: selectedSeats.filter(seatId => seatId !== id),
			});
		} else {
			this.setState({
				selectedSeats: selectedSeats.concat([id]),
			});
		}
	};

	handleSeatDoubleClicked = async (id: number) => {
		this.setState({
			selectedSeats: [id],
		});
	};

	handleClearSelection = () => {
		this.setState({
			selectedSeats: [],
			selectedReservation: null,
		});
	};

	handleClearSeatSelection = () => {
		this.setState({
			selectedSeats: [],
		});
	};

	handleReservationClicked = async (id: number) => {
		this.setState({
			selectedReservation: id,
		});
	};

	handleReservationDoubleClicked = async (id: number) => {
		const seatIds = await this.getSeatIdsByReservationId(id);
		this.setState({
			selectedSeats: seatIds,
		});
	};

	sendMutationCreateTicket = async (seatId: number, reservationId: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<createTicket, createTicketVariables>({
				mutation: CREATE_TICKET,
				variables: { seatId, reservationId },
			});
		} catch {
			enqueueSnackbar('Fehler beim Erstellen des Tickets', { variant: 'error' });
		}
	};

	sendMutationGenerateTickets = async () => {
		const { client, enqueueSnackbar, showId } = this.props;
		try {
			return await client.mutate<generateTicketsByShow, generateTicketsByShowVariables>({
				mutation: GENERATE_TICKETS_BY_SHOW,
				variables: { showId },
			});
		} catch {
			enqueueSnackbar('Fehler beim Generieren der Tickets', { variant: 'error' });
		}
	};

	sendMutationRemoveTicket = async (ticketId: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<removeTicket, removeTicketVariables>({
				mutation: REMOVE_TICKET,
				variables: { ticketId },
			});
		} catch {
			enqueueSnackbar('Fehler beim Entfernen des Tickets', { variant: 'error' });
		}
	};

	sendMutationFetchTicket = async (ticketId: number) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<fetchTicket, fetchTicketVariables>({
				mutation: FETCH_TICKET,
				variables: { ticketId },
			});
		} catch {
			enqueueSnackbar('Fehler beim Abholen des Tickets', { variant: 'error' });
		}
	};

	sendQuerySeatIsTakenByShow = async (seatId: number, showId: number): Promise<boolean> => {
		const { client } = this.props;
		const seat = client.readFragment<{ id: number; getTicketByShow: null | { id: number } }>({
			id: `Seat:${seatId}`,
			fragment: gql`
				fragment seatIsTakenByShow on Seat {
					id
					getTicketByShow (showId: ${showId}) {
						id
					}
				}
			`,
		});
		if (seat != null && seat.getTicketByShow != null) {
			return true;
		} else {
			return false;
		}
	};

	sendQueryUpdateSeat = async (seatId: number, showId: number) => {
		const { client } = this.props;
		return await client.query<updateSeat, updateSeatVariables>({
			query: UPDATE_SEAT_TICKET,
			variables: {
				seatId,
				showId,
			},
			fetchPolicy: 'network-only',
		});
	};

	sendQueryUpdateReservation = async (reservationId: number) => {
		const { client } = this.props;
		return await client.query<updateReservation, updateReservationVariables>({
			query: UPDATE_RESERVATION,
			variables: {
				reservationId,
			},
			fetchPolicy: 'network-only',
		});
	};

	handleAddTicketToReservation = async (): Promise<boolean> => {
		const { selectedReservation, selectedSeats } = this.state;
		const { enqueueSnackbar, showId } = this.props;
		if (selectedReservation != null && selectedSeats.length !== 0) {
			const reservationId = Number(selectedReservation);
			const mutations = selectedSeats.map(async seatId => {
				const seatIsTaken = await this.sendQuerySeatIsTakenByShow(seatId, showId);
				if (!seatIsTaken) {
					return this.sendMutationCreateTicket(seatId, reservationId);
				} else {
					enqueueSnackbar('Sitz ist schon besetzt für diese Show', {
						variant: 'warning',
					});
				}
			});
			await Promise.all(mutations);
			await this.sendQueryUpdateReservation(reservationId);
			const updates = selectedSeats.map(seatId => this.sendQueryUpdateSeat(seatId, showId));
			await Promise.all(updates);
			return true;
		} else {
			enqueueSnackbar('Du musst sowohl Sitze als auch Reservierung ausgewählt haben', {
				variant: 'warning',
			});
			return false;
		}
	};

	handleRemoveTicket = async (): Promise<boolean> => {
		const { selectedSeats } = this.state;
		const { enqueueSnackbar, showId } = this.props;
		if (selectedSeats.length !== 0) {
			const ticketIds = selectedSeats.map(seatId => this.getTicketIdBySeatId(seatId, showId));
			const mutations = ticketIds.map(ticketId => {
				if (ticketId != null) {
					return this.sendMutationRemoveTicket(ticketId);
				} else {
					enqueueSnackbar('Sitz ist nicht besetzt', {
						variant: 'warning',
					});
				}
			});
			await Promise.all(mutations);
			const updates = selectedSeats.map(seatId => this.sendQueryUpdateSeat(seatId, showId));
			await Promise.all(updates);
			return true;
		} else {
			enqueueSnackbar('Du musst Sitze ausgewählt haben', {
				variant: 'warning',
			});
			return false;
		}
	};

	handleFetchTicket = async (): Promise<boolean> => {
		const { selectedSeats } = this.state;
		const { enqueueSnackbar, showId } = this.props;
		if (selectedSeats.length !== 0) {
			const ticketIds = selectedSeats.map(seatId => this.getTicketIdBySeatId(seatId, showId));
			const mutations = ticketIds.map(async ticketId => {
				if (ticketId != null) {
					return this.sendMutationFetchTicket(ticketId);
				} else {
					enqueueSnackbar('Sitz ist nicht besetzt', {
						variant: 'warning',
					});
				}
			});
			await Promise.all(mutations);
			return true;
		} else {
			enqueueSnackbar('Du musst Sitze ausgewählt haben', {
				variant: 'warning',
			});
			return false;
		}
	};

	handleToggleMode = () => {
		const { mode } = this.state;
		if (mode === Mode.Placement) {
			this.setState({
				mode: Mode.Fetch,
			});
		}
		if (mode === Mode.Fetch) {
			this.setState({
				mode: Mode.Placement,
			});
		}
	};

	handleToggleRefreshMode = () => {
		const { refreshMode } = this.state;
		if (refreshMode === RefreshMode.Single) {
			this.setState({
				refreshMode: RefreshMode.Collaboration,
			});
		}
		if (refreshMode === RefreshMode.Collaboration) {
			this.setState({
				refreshMode: RefreshMode.Single,
			});
		}
	};

	handleGetReservationBySelectedSeat = () => {
		const { selectedSeats } = this.state;
		const { showId } = this.props;
		if (selectedSeats.length > 0) {
			const ticketId = this.getTicketIdBySeatId(selectedSeats[0], showId);
			if (ticketId != null) {
				const reservation = this.getReservationByTicketId(ticketId as number);
				if (reservation != null) {
					window.alert(
						`
						Buchungscode: ${reservation.reservation.bookingCode}
						Nachname: ${reservation.reservation.lastName}
						Vorname: ${reservation.reservation.firstName}
						Sitzanzahl: ${reservation.reservation.seatCount}
						`,
					);
				}
			}
		}
	};

	getTicketIdBySeatId = (seatId: number, showId: number): number | null => {
		const { client } = this.props;
		const seat = client.readFragment<{ id: number; getTicketByShow: null | { id: number } }>({
			id: `Seat:${seatId}`,
			fragment: gql`
				fragment seatIsTakenByShow on Seat {
					id
					getTicketByShow (showId: ${showId}) {
						id
					}
				}
			`,
		});
		if (seat != null && seat.getTicketByShow != null) {
			return seat.getTicketByShow.id;
		}
		return null;
	};

	getReservationByTicketId = (ticketId: number): reservationByTicket | null => {
		const { client } = this.props;
		const ticket = client.readFragment<reservationByTicket>({
			id: `Ticket:${ticketId}`,
			fragment: gql`
				fragment reservationByTicket on Ticket {
					id
					reservation {
						id
						bookingCode
						seatCount
						firstName
						lastName
					}
				}
			`,
		});
		return ticket;
	};

	getSeatIdsByReservationId = (reservationId: number): number[] => {
		const { client } = this.props;
		const reservation = client.readFragment<seatIds>({
			id: `Reservation:${reservationId}`,
			fragment: gql`
				fragment seatIds on Reservation {
					id
					tickets {
						seat {
							id
						}
					}
				}
			`,
		});

		if (reservation != null) {
			return reservation.tickets.map(ticket => ticket.seat.id);
		}
		return [];
	};

	render() {
		const { showId, classes } = this.props;
		const { selectedSeats, selectedReservation, loading, searchInput, mode, refreshMode } = this.state;
		const highlightedSeats =
			selectedReservation != null ? this.getSeatIdsByReservationId(Number(selectedReservation)) : [];
		return (
			<Query<getShowAudienceSpaceView, getShowAudienceSpaceViewVariables>
				query={GET_SHOW_AUDIENCESPACE_TICKET_VIEW}
				variables={{ id: showId }}
				pollInterval={refreshMode === RefreshMode.Collaboration ? 1500 : undefined}
				returnPartialData
			>
				{({ data, refetch }) => {
					if (
						data != null &&
						data.getShowById != null &&
						data.getShowById.audienceSpace != null &&
						data.getShowById.audienceSpace.seatRows != null
					) {
						const { audienceSpace } = data.getShowById;

						const seatRowData: ISeatRow[] =
							mode === Mode.Placement
								? this.computeSeatRowData(data, selectedSeats, highlightedSeats)
								: this.computeSeatRowDataFetch(data, selectedSeats, highlightedSeats);

						return (
							<div className={classes.root}>
								<Paper>
									<Grid container>
										<Grid item xs={12} lg={9}>
											<Grid container>
												<Grid className={classes.toolbar} item xs={12}>
													<Grid alignItems="center" container justify="space-between">
														<Grid item>
															<Grid container alignItems="center" justify="flex-start" direction="row">
																<Grid item>
																	<Tooltip title="Aktualisieren">
																		<Button
																			color="primary"
																			onClick={async () => {
																				this.setState({
																					loading: true,
																				});
																				await refetch();
																				this.setState({
																					loading: false,
																				});
																			}}
																		>
																			<RefreshOutlined />
																		</Button>
																	</Tooltip>
																</Grid>
																<Grid item>
																	<Tooltip title="Refresh-Modus ändern (Single/Collaborative)">
																		<Button color="primary" onClick={this.handleToggleRefreshMode}>
																			{refreshMode === RefreshMode.Single && <PersonOutlined />}
																			{refreshMode === RefreshMode.Collaboration && <GroupOutlined />}
																		</Button>
																	</Tooltip>
																</Grid>
																<Grid item>
																	<Tooltip title="Modus ändern (Abholung/Platzierung)">
																		<Button color="primary" onClick={this.handleToggleMode}>
																			Modus: {mode === Mode.Placement && 'Platzierung'}
																			{mode === Mode.Fetch && 'Abholung'}
																		</Button>
																	</Tooltip>
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Grid container alignItems="center" justify="center" direction="row">
																{mode === Mode.Placement && (
																	<Grid item>
																		<Tooltip title="Sitze der Reservierung hinzufügen">
																			<Button
																				color="primary"
																				onClick={async () => {
																					this.setState({
																						loading: true,
																					});
																					await this.handleAddTicketToReservation();
																					this.setState({
																						loading: false,
																						selectedSeats: [],
																					});
																				}}
																			>
																				<Add />
																			</Button>
																		</Tooltip>
																	</Grid>
																)}
																{mode === Mode.Placement && (
																	<Grid item>
																		<Tooltip title="Tickets der Sitze entfernen">
																			<Button
																				color="primary"
																				onClick={async () => {
																					this.setState({
																						loading: true,
																					});
																					await this.handleRemoveTicket();
																					this.setState({
																						loading: false,
																						selectedSeats: [],
																					});
																				}}
																			>
																				<Remove />
																			</Button>
																		</Tooltip>
																	</Grid>
																)}
																{mode === Mode.Placement && (
																	<Grid item>
																		<Tooltip title="Tickets automatisch generieren">
																			<Button
																				color="primary"
																				onClick={async () => {
																					this.setState({
																						loading: true,
																					});
																					await this.sendMutationGenerateTickets();
																					await refetch();
																					this.setState({
																						loading: false,
																					});
																				}}
																			>
																				<FlashAutoOutlined />
																			</Button>
																		</Tooltip>
																	</Grid>
																)}
																{mode === Mode.Fetch && (
																	<Grid item>
																		<Tooltip title="Tickets abholen">
																			<Button
																				color="primary"
																				onClick={async () => {
																					this.setState({
																						loading: true,
																					});
																					await this.handleFetchTicket();
																					this.setState({
																						loading: false,
																						selectedSeats: [],
																					});
																				}}
																			>
																				<HowToRegOutlined />
																			</Button>
																		</Tooltip>
																	</Grid>
																)}
																{mode === Mode.Fetch && (
																	<Grid item>
																		<Tooltip title="Wer sitzt hier?">
																			<Button
																				color="primary"
																				onClick={async () => {
																					this.handleGetReservationBySelectedSeat();
																				}}
																			>
																				<EventSeatOutlined />
																			</Button>
																		</Tooltip>
																	</Grid>
																)}
																<Grid item>
																	<Tooltip title="Selektion aufheben">
																		<Button color="primary" onClick={this.handleClearSelection}>
																			<CancelOutlined />
																		</Button>
																	</Tooltip>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												{loading && <LinearProgress />}
											</Grid>
											<Grid item xs={12}>
												<AudienceSpaceView
													key={audienceSpace.id}
													sizeX={audienceSpace.sizeX}
													sizeY={audienceSpace.sizeY}
													seatRowData={seatRowData}
													seatClicked={this.handleSeatClicked}
													seatDoubleClicked={this.handleSeatDoubleClicked}
												/>
											</Grid>
										</Grid>
										<Grid className={classes.rightSideBar} item xs={12} lg={3}>
											<Grid container>
												<Grid className={classes.searchInputContainer} item xs={12}>
													<SearchInput
														onChange={value => {
															this.setState({
																searchInput: value,
															});
														}}
														className={classes.searchInput}
													/>
												</Grid>
												<Grid className={classes.listView} item xs={12}>
													<List>
														{data.getShowById.reservations
															.filter(reservation => {
																if (searchInput === '') {
																	return true;
																}
																if (
																	reservation.firstName.includes(searchInput) ||
																	reservation.lastName.includes(searchInput) ||
																	reservation.bookingCode.includes(searchInput) ||
																	(reservation.email != null && reservation.email.includes(searchInput))
																) {
																	return true;
																}
																return false;
															})
															.sort((a, b) => b.priority.value - a.priority.value)
															.splice(0, 12)
															.map(reservation => {
																const reservationChips = reservation.tickets
																	.sort((a, b) => a.seat.index - b.seat.index)
																	.map(ticket => (
																		<Chip
																			style={{ marginRight: '2px', marginBottom: '2px' }}
																			size="small"
																			variant={ticket.fetched ? 'outlined' : 'default'}
																			key={ticket.id}
																			label={ticket.seat.displayName}
																		/>
																	));
																const secondary: React.ReactNode =
																	mode === Mode.Placement
																		? `Sitzplätze: ${reservation.tickets.length}/${reservation.seatCount}`
																		: reservationChips;
																return [
																	<ListItem
																		onClick={() => {
																			this.handleReservationClicked(reservation.id);
																		}}
																		onDoubleClick={() => {
																			this.handleReservationDoubleClicked(reservation.id);
																		}}
																		selected={this.state.selectedReservation === reservation.id}
																		key={`item_${reservation.id}`}
																	>
																		<ListItemText
																			primary={reservation.firstName + ' ' + reservation.lastName}
																			secondary={secondary}
																		/>
																		<Chip
																			label={reservation.priority.displayName}
																			style={{
																				backgroundColor: reservation.priority.color,
																				color: '#ffffff',
																				height: '24px',
																			}}
																		/>
																	</ListItem>,
																	<Divider key={`divider_${reservation.id}`} />,
																];
															})}
													</List>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Paper>
							</div>
						);
					} else {
						return <LoadingView />;
					}
				}}
			</Query>
		);
	}

	private computeSeatRowData(
		data: getShowAudienceSpaceView,
		selectedSeats: number[],
		highlightedSeats: number[],
	): ISeatRow[] {
		return data.getShowById.audienceSpace.seatRows.map(seatRow => ({
			id: seatRow.id,
			displayName: seatRow.displayName,
			x: seatRow.x,
			y: seatRow.y,
			rotation: seatRow.rotation,
			fillFrom: seatRow.fillFrom.toLowerCase() as ('center' | 'left' | 'right'),
			disabled: seatRow.disabled,
			selected: false,
			seats: seatRow.seats.map(seat => ({
				...seat,
				activated: selectedSeats.includes(seat.id),
				highlighted: highlightedSeats.includes(seat.id),
				taken: seat.getTicketByShow != null ? true : false,
			})),
		}));
	}

	private computeSeatRowDataFetch(
		data: getShowAudienceSpaceView,
		selectedSeats: number[],
		highlightedSeats: number[],
	): ISeatRow[] {
		return data.getShowById.audienceSpace.seatRows.map(seatRow => ({
			id: seatRow.id,
			displayName: seatRow.displayName,
			x: seatRow.x,
			y: seatRow.y,
			rotation: seatRow.rotation,
			fillFrom: seatRow.fillFrom.toLowerCase() as ('center' | 'left' | 'right'),
			disabled: seatRow.disabled,
			selected: false,
			seats: seatRow.seats.map(seat => ({
				...seat,
				disabled: seat.getTicketByShow != null ? false : true,
				activated: selectedSeats.includes(seat.id),
				highlighted: highlightedSeats.includes(seat.id),
				taken: seat.getTicketByShow != null ? seat.getTicketByShow.fetched : true,
			})),
		}));
	}
}

export default withSnackbar(
	withStyles(styles)(withApollo<IShowAudienceSpaceViewProps>(ShowAudienceSpaceView)),
);
