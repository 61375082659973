import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Externals
import compose from 'recompose/compose';
import validate from 'validate.js';
import _ from 'underscore';
import * as auth0 from 'auth0-js';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { Grid, Button, IconButton, CircularProgress, TextField, Typography } from '@material-ui/core';

// Material icons
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

// Form validation schema
import schema from './schema';
import { History } from 'history';

const webAuth = new auth0.WebAuth({
	domain: 'ticketos.eu.auth0.com',
	clientID: 'ePojXGdFqZCxQBf9LMbo5XLXceShwm5E',
	responseType: 'token id_token',
	redirectUri:
		window.location.host === 'ticketos.io:3000'
			? 'http://ticketos.io:3000/sign-in'
			: 'https://dashboard.resyotic.com/sign-in',
	audience: 'https://ticketos.io/api',
});

// Service methods
async function signIn(email: string, password: string) {
	webAuth.login(
		{
			realm: 'Username-Password-Authentication',
			email,
			password,
			scope: 'openid profile email create:project edit:project delete:project',
		},
		function(err, result) {
			if (err) {
				throw err;
			} else {
				return result;
			}
		},
	);
}

interface ISignInProps extends WithStyles<typeof styles> {
	className: string;
	history: History;
}

interface ISignInState {
	values: {
		email: string;
		password: string;
	};
	touched: {
		email: boolean;
		password: boolean;
	};
	errors: {
		email?: any[];
		password?: any[];
	};
	isValid: boolean;
	isLoading: boolean;
	submitError?: string | null;
	serviceError: Error | null;
}

class SignIn extends Component<ISignInProps, ISignInState> {
	state = {
		values: {
			email: '',
			password: '',
		},
		touched: {
			email: false,
			password: false,
		},
		errors: {
			email: [],
			password: [],
		},
		isValid: false,
		isLoading: false,
		submitError: null,
		serviceError: null,
	};

	handleBack = () => {
		const { history } = this.props;

		history.goBack();
	};

	validateForm = _.debounce(() => {
		const { values } = this.state;

		const newState = { ...this.state };
		const errors = validate(values, schema);

		newState.errors = errors || {};
		newState.isValid = errors ? false : true;

		this.setState(newState);
	}, 300);

	handleFieldChange = (field: 'email' | 'password', value: string) => {
		const newState = { ...this.state };

		newState.submitError = null;
		newState.touched[field] = true;
		newState.values[field] = value;

		this.setState(newState, this.validateForm);
	};

	handleSignIn = async () => {
		try {
			const { values } = this.state;

			this.setState({ isLoading: true });

			await signIn(values.email, values.password);

			this.setState({
				isLoading: false,
			});
		} catch (error) {
			this.setState({
				isLoading: false,
				serviceError: error,
			});
		}
	};

	componentWillMount() {
		const { history } = this.props;
		webAuth.parseHash({ hash: window.location.hash }, function(err, authResult) {
			if (err) {
				// Error occured
			} else if (err == null && authResult == null) {
				// eslint-disable-next-line
				console.debug('no hash found');
				// No Hash found
			} else {
				if (!(authResult == null) && !(authResult.accessToken == null)) {
					webAuth.client.userInfo(authResult.accessToken, function(/* err, user */) {
						window.localStorage.setItem('token', authResult.accessToken as string);
						window.localStorage.setItem(
							'tokenExpiresAt',
							(Date.now().valueOf() + (authResult.expiresIn as number) * 1000).toString(),
						);
						history.push('/projects');
					});
				}
			}
		});
	}

	render() {
		const { classes } = this.props;
		const { values, touched, errors, isValid, submitError, isLoading } = this.state;

		const showEmailError = touched.email && errors.email;
		const showPasswordError = touched.password && errors.password;

		return (
			<div className={classes.root}>
				<Grid className={classes.grid} container>
					<Grid className={classes.quoteWrapper} item lg={5}>
						<div className={classes.quote}>
							<div className={classes.quoteInner}>
								<Typography className={classes.quoteText} variant="h1">
									When you think a reservation system couldn&apos;t be better
								</Typography>
								<div>
									<Typography className={classes.name} variant="body1">
										Anybody
									</Typography>
									<Typography className={classes.bio} variant="body2">
										Somebody at somewhere
									</Typography>
								</div>
							</div>
						</div>
					</Grid>
					<Grid className={classes.content} item lg={7} xs={12}>
						<div className={classes.content}>
							<div className={classes.contentHeader}>
								<IconButton className={classes.backButton} onClick={this.handleBack}>
									<ArrowBackIcon />
								</IconButton>
							</div>
							<div className={classes.contentBody}>
								<div className={classes.column}>
									<div className={classes.logoWrapper}>
										<img alt="TicketOS" className={classes.logo} src="/images/logos/logo.png" />
									</div>
									<form className={classes.form}>
										<Typography className={classes.title} variant="h2">
											Sign in
										</Typography>
										<div className={classes.fields}>
											<TextField
												className={classes.textField}
												label="Email address"
												name="email"
												onChange={event => this.handleFieldChange('email', event.target.value)}
												type="text"
												value={values.email}
												variant="outlined"
											/>
											{showEmailError && (
												<Typography className={classes.fieldError} variant="body2">
													{errors.email.length !== 0 ? errors.email[0] : ''}
												</Typography>
											)}
											<TextField
												className={classes.textField}
												label="Password"
												name="password"
												onChange={event => this.handleFieldChange('password', event.target.value)}
												type="password"
												value={values.password}
												variant="outlined"
											/>
											{showPasswordError && (
												<Typography className={classes.fieldError} variant="body2">
													{errors.password.length !== 0 ? errors.password[0] : ''}
												</Typography>
											)}
										</div>
										{submitError && (
											<Typography className={classes.submitError} variant="body2">
												{submitError}
											</Typography>
										)}
										{isLoading ? (
											<CircularProgress className={classes.progress} />
										) : (
											<Button
												className={classes.signInButton}
												color="primary"
												disabled={!isValid}
												onClick={this.handleSignIn}
												size="large"
												variant="contained"
											>
												Sign in now
											</Button>
										)}
										<Typography className={classes.signUp} variant="body1">
											Don&apos;t have an account
											<Link className={classes.signUpUrl} to="/sign-up">
												Sign up
											</Link>
										</Typography>
									</form>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default compose<ISignInProps, {}>(
	withRouter,
	withStyles(styles),
)(SignIn);
