import React, { Component } from 'react';
import { withStyles, WithStyles, Tabs, Tab, Paper } from '@material-ui/core';

import styles from './styles';
import { RouteComponentProps, withRouter } from 'react-router';
import GeneralSettings from './components/GeneralSettings';
import { AudienceSpaceSettings, MemberSettings, ReservationPrioritySettings } from './components';

interface RouteParams {
	identifier: string;
	key: 'general' | 'extended' | 'audiencespace' | 'members' | 'priorities';
	subKey?: string;
}

interface IProjectSettingsProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {}

interface IProjectSettingsState {
	currentTab: 'general' | 'extended' | 'audiencespace' | 'members' | 'priorities';
}

class ProjectSettings extends Component<IProjectSettingsProps, IProjectSettingsState> {
	componentWillMount() {
		const { match } = this.props;
		this.setState({ currentTab: match.params.key });
	}

	handleChange = (_: any, value: 'general' | 'extended' | 'audiencespace' | 'members' | 'priorities') => {
		const { history, match } = this.props;
		this.setState({
			currentTab: value,
		});
		history.push(`/p/${match.params.identifier}/settings/${value}`);
	};

	render() {
		const { classes, match } = this.props;
		const { currentTab } = this.state;
		return (
			<div className={classes.root}>
				<Paper className={classes.tabs}>
					<Tabs
						value={currentTab}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
						variant="fullWidth"
						centered
					>
						<Tab value="general" label="Allgemein" />
						<Tab value="extended" label="Erweitert" />
						<Tab value="members" label="Mitglieder" />
						<Tab value="priorities" label="Prioritäten" />
						<Tab value="audiencespace" label="Zuschauerraum" />
					</Tabs>
				</Paper>
				{currentTab === 'audiencespace' && (
					<AudienceSpaceSettings projectIdentifier={match.params.identifier} subKey={match.params.subKey} />
				)}
				{currentTab === 'general' && <GeneralSettings projectIdentifier={match.params.identifier} />}
				{currentTab === 'members' && <MemberSettings projectIdentifier={match.params.identifier} />}
				{currentTab === 'priorities' && (
					<ReservationPrioritySettings projectIdentifier={match.params.identifier} />
				)}
			</div>
		);
	}
}

export default withStyles(styles)(withRouter<IProjectSettingsProps>(ProjectSettings));
