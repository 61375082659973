import React, { Component } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { ArrowUpward as ArrowUpwardIcon, Money as MoneyIcon } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

interface IBudgetProps extends WithStyles<typeof styles> {
	className: string;
}

class Budget extends Component<IBudgetProps, {}> {
	render() {
		const { classes, className, ...rest } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<Paper {...rest} className={rootClassName}>
				<div className={classes.content}>
					<div>
						<Typography className={classes.title} variant="body2">
							TOTAL RESERVATIONS
						</Typography>
						<Typography className={classes.value} variant="h3">
							56000
						</Typography>
					</div>
					<div className={classes.iconWrapper}>
						<MoneyIcon className={classes.icon} />
					</div>
				</div>
				<div className={classes.footer}>
					<Typography className={classes.differencePositive} variant="body2">
						<ArrowUpwardIcon />
						25
					</Typography>
					<Typography className={classes.caption} variant="caption">
						in den letzten 24 Stunden
					</Typography>
				</div>
			</Paper>
		);
	}
}

export default withStyles(styles)(Budget);
