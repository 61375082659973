import * as React from 'react';
import { ReservationGrid, LoadingView } from 'components';
import { Paper, WithStyles, LinearProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { getProjectReservationsVariables, getProjectReservations } from '__schema__/getProjectReservations';
import { Query } from 'react-apollo';

import styles from './styles';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router';

interface RouteParams {
	identifier: string;
}

interface IReservationListProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	projectIdentifier: string;
	className?: string;
}

const GET_PROJECT_RESERVATIONS = gql`
	query getProjectReservations($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			reservations {
				id
				bookingCode
				firstName
				lastName
				email
				seatCount
				confirmed
				createdAt
				splitEnabled
				comment
				show {
					id
					displayName
				}
				priority {
					id
					value
					displayName
					color
				}
			}
		}
	}
`;

class ReservationList extends React.Component<IReservationListProps, {}> {
	public render(): React.ReactNode {
		const { className, projectIdentifier, classes, history, match } = this.props;

		const rootClass = classNames(classes.root, className);

		return (
			<Query<getProjectReservations, getProjectReservationsVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_RESERVATIONS}
			>
				{({ data, refetch, loading }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<div className={rootClass}>
								<Paper>
									{loading && <LinearProgress />}
									<ReservationGrid
										rows={data.getProjectByIdentifier.reservations}
										defaultHiddenColumns={[]}
										onRefreshClick={() => {
											refetch();
										}}
										onReservationClick={reservation =>
											history.push(`/p/${match.params.identifier}/reservation/${reservation.id}`)
										}
									/>
								</Paper>
							</div>
						);
					}
					return <LoadingView />;
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(withRouter<IReservationListProps>(ReservationList));
