import React, { Component } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { IconButton, Grid, Typography } from '@material-ui/core';

// Material icons
import { ChevronRight as ChevronRightIcon, ChevronLeft as ChevronLeftIcon } from '@material-ui/icons';

// Custom components
import { ShowToolbar, ShowCard, CreateShowDialog } from './components';

// Component styles
import styles from './styles';

// Graphql
import { Query } from 'react-apollo';
import { getProjectShows, getProjectShowsVariables } from '__schema__/getProjectShows';
import gql from 'graphql-tag';

interface RouteParams {
	identifier: string;
}

interface IShowListProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	projectIdentifier: string;
}

interface IShowListState {
	createShowDialogOpen: boolean;
	searchInput: string;
}

const GET_PROJECT_SHOWS = gql`
	query getProjectShows($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			shows {
				id
				displayName
				beginsAt
				maxTicketCount
				reservedSeatCount
				audienceSpace {
					id
					displayName
					totalUsableSeatCount
				}
			}
		}
	}
`;

class ShowList extends Component<IShowListProps, IShowListState> {
	state = {
		createShowDialogOpen: false,
		searchInput: '',
	};

	handleOpenShowCreateDialog = () => {
		this.setState({
			createShowDialogOpen: true,
		});
	};

	handleCloseShowCreateDialog = () => {
		this.setState({
			createShowDialogOpen: false,
		});
	};

	handleChangeSearchInput = (value: string) => {
		this.setState({
			searchInput: value,
		});
	};

	renderShows(
		shows: {
			id: number;
			displayName: string;
			beginsAt: string;
			takenSeats: number;
			maxSeats: number;
			audienceSpace: { displayName: string };
		}[],
	) {
		const { projectIdentifier } = this.props;
		const { searchInput } = this.state;
		const filteredShows = shows.filter(show => show.displayName.includes(searchInput));
		const sortedShows = filteredShows.sort((showA, showB) => {
			const dateA = new Date(showA.beginsAt).valueOf();
			const dateB = new Date(showB.beginsAt).valueOf();
			return dateB - dateA;
		});
		const firstPast = sortedShows.findIndex(show => Date.now() > new Date(show.beginsAt).valueOf());
		let displayedShows = [];
		if (firstPast !== -1) {
			const pastShows = sortedShows.slice(firstPast);
			const nextShows = sortedShows.slice(0, firstPast);
			const nextShowsReversed = nextShows.reverse();
			displayedShows = nextShowsReversed.concat(pastShows);
		} else {
			displayedShows = sortedShows.reverse();
		}
		return displayedShows.map(show => (
			<Grid key={show.id} item lg={3} md={6} xs={12}>
				<Link to={`/p/${projectIdentifier}/show/${show.id}`}>
					<ShowCard
						show={{
							id: show.id,
							displayName: show.displayName,
							maxSeats: show.maxSeats,
							takenSeats: show.takenSeats,
							date: new Date(show.beginsAt),
							locationName: show.audienceSpace.displayName,
						}}
					/>
				</Link>
			</Grid>
		));
	}

	render() {
		const { classes, projectIdentifier } = this.props;
		const { createShowDialogOpen } = this.state;

		return (
			<Query<getProjectShows, getProjectShowsVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_SHOWS}
			>
				{({ data, refetch }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<div className={classes.root}>
								<ShowToolbar
									onSearchChange={this.handleChangeSearchInput}
									onClickAddShow={this.handleOpenShowCreateDialog}
								/>
								<CreateShowDialog
									open={createShowDialogOpen}
									handleClose={this.handleCloseShowCreateDialog}
									handleCreated={refetch}
									projectId={data.getProjectByIdentifier.id}
								/>
								<div className={classes.content}>
									<Grid container spacing={3}>
										{this.renderShows(
											data.getProjectByIdentifier.shows.map(show => ({
												...show,
												maxSeats:
													show.maxTicketCount === -1 ||
													show.maxTicketCount > show.audienceSpace.totalUsableSeatCount
														? show.audienceSpace.totalUsableSeatCount
														: show.maxTicketCount,
												takenSeats: show.reservedSeatCount,
											})),
										)}
									</Grid>
								</div>
								<div className={classes.pagination}>
									<Typography variant="caption">1-6 of 20</Typography>
									<IconButton>
										<ChevronLeftIcon />
									</IconButton>
									<IconButton>
										<ChevronRightIcon />
									</IconButton>
								</div>
							</div>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(withRouter(ShowList));
