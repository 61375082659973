import { createStyles } from '@material-ui/core';

const styles = () => {
	return createStyles({
		root: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			marginTop: '200px',
		},
	});
};

export default styles;
