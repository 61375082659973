/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Indicates in which order a seat row should be filled
 */
export enum FillFrom {
  Center = "Center",
  Left = "Left",
  Right = "Right",
}

/**
 * Rank of a member in one specific project
 */
export enum ProjectMemberRank {
  Administrator = "Administrator",
  Editor = "Editor",
  Inspector = "Inspector",
  Moderator = "Moderator",
  Owner = "Owner",
  Standard = "Standard",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
