import React, { Component, ReactNode } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	LinearProgress,
	SnackbarContent,
	MenuItem,
} from '@material-ui/core';
import styles from './styles';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import { TextField, Switch } from 'formik-material-ui';
import validate from 'validate.js';
import schema from './schema';
import { ApolloError } from 'apollo-client';
import {
	getProjectCreateReservationVariables,
	getProjectCreateReservation,
} from '__schema__/getProjectCreateReservation';
import { createReservationVariables, createReservation } from '__schema__/createReservation';
import {
	getProjectEditReservation,
	getProjectEditReservationVariables,
} from '__schema__/getProjectEditReservation';
import { editReservationVariables, editReservation } from '__schema__/editReservation';
import { RouteComponentProps, withRouter } from 'react-router';

const GET_PROJECT_CREATE_RESERVATION = gql`
	query getProjectCreateReservation($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			reservationPriorities {
				id
				displayName
				value
				color
			}
			shows {
				id
				displayName
				beginsAt
			}
			defaultReservationPriority {
				id
			}
		}
	}
`;

const CREATE_RESERVATION = gql`
	mutation createReservation(
		$showId: Int!
		$reservationPriorityId: Int!
		$firstName: String!
		$lastName: String!
		$seatCount: Int!
		$email: String
		$splitEnabled: Boolean!
		$comment: String
		$confirmed: Boolean!
	) {
		createReservation(
			createReservationData: {
				showId: $showId
				reservationPriorityId: $reservationPriorityId
				firstName: $firstName
				lastName: $lastName
				seatCount: $seatCount
				email: $email
				splitEnabled: $splitEnabled
				comment: $comment
				confirmed: $confirmed
			}
		) {
			id
			show {
				id
			}
			priority {
				id
			}
			firstName
			lastName
			email
			confirmed
			splitEnabled
			comment
			seatCount
		}
	}
`;

const GET_PROJECT_EDIT_RESERVATION = gql`
	query getProjectEditReservation($identifier: String!, $reservationId: Int!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			reservationPriorities {
				id
				displayName
				value
				color
			}
			shows {
				id
				displayName
				beginsAt
			}
		}
		getReservationById(id: $reservationId) {
			id
			show {
				id
			}
			priority {
				id
			}
			firstName
			lastName
			email
			confirmed
			splitEnabled
			comment
			seatCount
		}
	}
`;

const EDIT_RESERVATION = gql`
	mutation editReservation(
		$reservationId: Int!
		$showId: Int!
		$reservationPriorityId: Int!
		$firstName: String!
		$lastName: String!
		$seatCount: Int!
		$email: String
		$splitEnabled: Boolean!
		$comment: String
		$confirmed: Boolean!
	) {
		editReservation(
			editReservationData: {
				reservationId: $reservationId
				showId: $showId
				reservationPriorityId: $reservationPriorityId
				firstName: $firstName
				lastName: $lastName
				seatCount: $seatCount
				email: $email
				splitEnabled: $splitEnabled
				comment: $comment
				confirmed: $confirmed
			}
		) {
			id
			show {
				id
			}
			priority {
				id
			}
			firstName
			lastName
			email
			confirmed
			splitEnabled
			comment
			seatCount
		}
	}
`;

interface RouteParams {
	identifier: string;
}

interface ICreateReservationProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	projectIdentifier: string;
	editMode?: boolean;
	reservationId?: number;
	initialValues: {
		email?: string;
		firstName?: string;
		lastName?: string;
		seatCount?: number;
		showId?: number;
		reservationPriorityId?: number;
		splitEnabled?: boolean;
		confirmed?: boolean;
	};
}

interface QueryAndMutationProps {
	children: ({
		submit,
		data,
		loading,
		submitError,
	}: {
		submit: any;
		data: QueryData;
		loading: boolean;
		submitError?: ApolloError;
	}) => ReactNode;
}

interface QueryData {
	initialValues: {
		email: string | null;
		firstName: string;
		lastName: string;
		seatCount: number;
		showId: number;
		reservationPriorityId: number;
		splitEnabled: boolean;
		confirmed: boolean;
	};
	shows: {
		displayName: string;
		beginsAt: Date;
		id: number;
	}[];
	reservationPriorities: {
		id: number;
		color: string;
		displayName: string;
		value: number;
	}[];
}

class CreateReservation extends Component<ICreateReservationProps, {}> {
	CreateQueryAndMutation: React.FC<QueryAndMutationProps> = props => {
		const { projectIdentifier, initialValues } = this.props;
		return (
			<Query<getProjectCreateReservation, getProjectCreateReservationVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_CREATE_RESERVATION}
			>
				{({ data, loading }) => {
					if (data != null && data.getProjectByIdentifier != null) {
						return (
							<Mutation<createReservation, createReservationVariables> mutation={CREATE_RESERVATION}>
								{(submit, { error }) => {
									const processedData: QueryData = {
										initialValues: {
											firstName: initialValues.firstName ? initialValues.firstName : '',
											lastName: initialValues.lastName ? initialValues.lastName : '',
											email: initialValues.email ? initialValues.email : null,
											seatCount: initialValues.seatCount ? Number(initialValues.seatCount) : 2,
											showId: initialValues.showId ? Number(initialValues.showId) : -1,
											reservationPriorityId: initialValues.reservationPriorityId
												? Number(initialValues.reservationPriorityId)
												: data.getProjectByIdentifier.defaultReservationPriority.id,
											splitEnabled: false,
											confirmed: true,
										},
										shows: [...data.getProjectByIdentifier.shows],
										reservationPriorities: [...data.getProjectByIdentifier.reservationPriorities],
									};
									return props.children({ submit, data: processedData, loading, submitError: error });
								}}
							</Mutation>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	};

	EditQueryAndMutation: React.FC<QueryAndMutationProps> = props => {
		const { projectIdentifier, reservationId, initialValues } = this.props;
		return (
			<Query<getProjectEditReservation, getProjectEditReservationVariables>
				variables={{ identifier: projectIdentifier, reservationId: reservationId ? reservationId : -1 }}
				query={GET_PROJECT_EDIT_RESERVATION}
			>
				{({ data, loading }) => {
					if (data != null && data.getProjectByIdentifier != null && data.getReservationById != null) {
						return (
							<Mutation<editReservation, editReservationVariables> mutation={EDIT_RESERVATION}>
								{(submit, { error }) => {
									const {
										firstName,
										lastName,
										email,
										seatCount,
										show,
										priority,
										splitEnabled,
										confirmed,
									} = data.getReservationById;
									const processedData: QueryData = {
										initialValues: {
											firstName: initialValues.firstName ? initialValues.firstName : firstName,
											lastName: initialValues.lastName ? initialValues.lastName : lastName,
											email: initialValues.email ? initialValues.email : email ? email : null,
											seatCount: initialValues.seatCount ? Number(initialValues.seatCount) : seatCount,
											showId: initialValues.showId ? Number(initialValues.showId) : show.id,
											reservationPriorityId: initialValues.reservationPriorityId
												? Number(initialValues.reservationPriorityId)
												: priority.id,
											splitEnabled: splitEnabled,
											confirmed: confirmed,
										},
										shows: [...data.getProjectByIdentifier.shows],
										reservationPriorities: [...data.getProjectByIdentifier.reservationPriorities],
									};
									return props.children({ submit, data: processedData, loading, submitError: error });
								}}
							</Mutation>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	};

	render() {
		const { classes, editMode, reservationId, match, history } = this.props;
		const QueryAndMutation = !editMode ? this.CreateQueryAndMutation : this.EditQueryAndMutation;
		return (
			<QueryAndMutation>
				{({ submit, data, submitError }) => {
					return (
						<Formik
							initialValues={{
								...data.initialValues,
							}}
							validate={values => {
								values.email = values.email === '' ? null : values.email;
								const errors = validate(values, schema);
								return errors;
							}}
							onSubmit={async (values, { setSubmitting }) => {
								try {
									const result = await submit({
										variables: {
											...values,
											reservationId: editMode ? reservationId : undefined,
											showId: Number(values.showId),
											reservationPriorityId: Number(values.reservationPriorityId),
											seatCount: Number(values.seatCount),
										},
									});
									if (result != null && result.data != null) {
										setSubmitting(false);
										history.push(
											`/p/${match.params.identifier}/reservation/${
												result.data.editReservation != null
													? result.data.editReservation.id
													: result.data.createReservation.id
											}`,
										);
									} else {
										setSubmitting(false);
										window.alert('error');
									}
								} catch {
									setSubmitting(false);
									window.alert('error');
								}
							}}
							render={props => (
								<div className={classes.root}>
									<Portlet className={classes.portlet}>
										<PortletHeader>
											<Typography variant="h4">Reservierung erstellen</Typography>
										</PortletHeader>
										<PortletContent>
											<Form>
												<Grid container spacing={2}>
													<Grid item xs={12}>
														<Typography color="primary" variant="h5">
															Persönliche Informationen
														</Typography>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">Vorname</Typography>
														<Field
															className={classes.fullWidthField}
															name="firstName"
															label="Vorname"
															variant="outlined"
															component={TextField}
														/>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">Nachname</Typography>
														<Field
															className={classes.fullWidthField}
															name="lastName"
															label="Nachname"
															variant="outlined"
															component={TextField}
														/>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">E-Mail (optional)</Typography>
														<Field
															className={classes.fullWidthField}
															name="email"
															label="E-Mail"
															variant="outlined"
															component={TextField}
														/>
													</Grid>
													<Grid item xs={12}>
														<Typography color="primary" variant="h5">
															Reservierungsdaten
														</Typography>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">Sitzanzahl</Typography>
														<Field
															className={classes.fullWidthField}
															name="seatCount"
															label="Sitzanzahl"
															variant="outlined"
															component={TextField}
														/>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">Show</Typography>
														<Field
															className={classes.fullWidthField}
															name="showId"
															label="Show"
															select
															variant="outlined"
															component={TextField}
														>
															<MenuItem disabled key={-1} value={-1}>
																---
															</MenuItem>
															{data.shows.map(show => (
																<MenuItem key={show.id} value={show.id}>
																	{show.displayName}
																</MenuItem>
															))}
														</Field>
													</Grid>
													<Grid item xs={12} md={6}>
														<Typography variant="h6">Priorität</Typography>
														<Field
															className={classes.fullWidthField}
															name="reservationPriorityId"
															label="Priorität"
															select
															variant="outlined"
															component={TextField}
														>
															<MenuItem disabled key={-1} value={-1}>
																---
															</MenuItem>
															{data.reservationPriorities
																.sort((a, b) => b.value - a.value)
																.map(priority => (
																	<MenuItem key={priority.id} value={priority.id}>
																		<div
																			style={{ backgroundColor: priority.color }}
																			className={classes.priorityColor}
																		/>
																		{priority.displayName}
																	</MenuItem>
																))}
														</Field>
													</Grid>
													<Grid item xs={6} md={3}>
														<Typography variant="h6">Sitzplätze aufteilen</Typography>
														<Field
															name="splitEnabled"
															label="Sitzplätze aufteilen"
															variant="outlined"
															component={Switch}
														/>
													</Grid>
													<Grid item xs={6} md={3}>
														<Typography variant="h6">Bestätigt</Typography>
														<Field name="confirmed" label="Bestätigt" variant="outlined" component={Switch} />
													</Grid>
												</Grid>
											</Form>
										</PortletContent>
										{props.isSubmitting && <LinearProgress />}
										{submitError && <SnackbarContent message={submitError.message} />}
										<PortletFooter>
											<div className={classes.row}>
												<span className={classes.spacer} />
												<Button onClick={props.submitForm} color="primary" variant="contained">
													Speichern
												</Button>
											</div>
										</PortletFooter>
									</Portlet>
								</div>
							)}
						/>
					);
				}}
			</QueryAndMutation>
		);
	}
}

export default withStyles(styles)(withRouter<ICreateReservationProps>(CreateReservation));
