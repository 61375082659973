import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
import { withStyles } from '@material-ui/core';

import styles from './styles';
import { SeatRow } from './components';

import ReactResizeDetector from 'react-resize-detector';

export interface ISeat {
	id: number;
	index: number;
	disabled?: boolean;
	taken?: boolean;
	activated?: boolean;
	highlighted?: boolean;
}

export interface ISeatRow {
	id: number;
	disabled?: boolean;
	displayName: string;
	seats: ISeat[];
	x: number;
	y: number;
	rotation?: number;
	fillFrom?: 'center' | 'right' | 'left';
	selected?: boolean;
}

interface IAudienceSpaceViewProps {
	sizeX: number;
	sizeY: number;
	seatRowsDraggable?: boolean;
	seatRowData: ISeatRow[];
	seatRowMoved?: (id: number, x: number, y: number) => void;
	seatRowClicked?: (id: number) => void;
	seatRowDoubleClicked?: (id: number) => void;
	seatClicked?: (id: number) => void;
	seatDoubleClicked?: (id: number) => void;
}

interface IAudienceSpaceViewState {
	initialized: boolean;
	width: number;
	height: number;
	scaleX: number;
	scaleY: number;
}

class AudienceSpaceView extends Component<IAudienceSpaceViewProps, IAudienceSpaceViewState> {
	stageDivRef: React.RefObject<HTMLDivElement>;

	constructor(props: IAudienceSpaceViewProps) {
		super(props);
		this.stageDivRef = React.createRef();
		this.state = {
			initialized: false,
			width: 0,
			height: 0,
			scaleX: 1,
			scaleY: 1,
		};
	}

	componentDidMount() {
		this.initializeAudienceSpaceView();
	}

	initializeAudienceSpaceView = () => {
		if (!(this.stageDivRef.current == null)) {
			const width: number = this.stageDivRef.current.clientWidth;
			const scalingFactor: number = width / this.props.sizeX;
			const height = scalingFactor * this.props.sizeY;
			this.setState({
				initialized: true,
				width,
				height,
				scaleX: scalingFactor,
				scaleY: scalingFactor,
			});
		} else {
			throw Error('Couldnt initialize AudienceSpaceView because of missing div reference');
		}
	};

	render() {
		const { initialized, width, height, scaleX, scaleY } = this.state;

		const {
			seatRowData,
			seatRowsDraggable,
			seatRowMoved,
			seatClicked,
			seatDoubleClicked,
			seatRowClicked,
			seatRowDoubleClicked,
		} = this.props;

		if (!initialized) {
			return <div ref={this.stageDivRef} />;
		}
		return (
			<div ref={this.stageDivRef}>
				<ReactResizeDetector handleWidth onResize={this.initializeAudienceSpaceView} />
				<Stage width={width} height={height} scaleX={scaleX} scaleY={scaleY}>
					<Layer>
						{seatRowData.map(seatRow => (
							<SeatRow
								draggable={seatRowsDraggable}
								key={seatRow.id}
								id={seatRow.id}
								x={seatRow.x}
								y={seatRow.y}
								rotation={seatRow.rotation}
								selected={seatRow.selected}
								seatData={seatRow.seats}
								displayName={seatRow.displayName}
								fillFrom={seatRow.fillFrom}
								onDragEnd={seatRowMoved}
								onSeatClick={seatClicked}
								onSeatDoubleClick={seatDoubleClicked}
								onSeatRowClick={seatRowClicked}
								onSeatRowDoubleClick={seatRowDoubleClicked}
								disabled={seatRow.disabled}
							/>
						))}
					</Layer>
				</Stage>
			</div>
		);
	}
}

export default withStyles(styles)(AudienceSpaceView);
