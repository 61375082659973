import React from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { Input } from '@material-ui/core';

// Material icons
import { Search as SearchIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';

interface ISearchInputProps extends WithStyles<typeof styles> {
	className?: string;
	onChange?: (value: string) => void;
	style?: any;
}

const SearchInput: React.FC<ISearchInputProps> = (props: ISearchInputProps) => {
	const { classes, className, onChange, style, ...rest } = props;

	const rootClassName = classNames(classes.root, className);

	return (
		<div className={rootClassName} style={style}>
			<SearchIcon className={classes.icon} />
			<Input
				{...rest}
				className={classes.input}
				disableUnderline
				onChange={evt => {
					if (onChange) {
						onChange(evt.target.value);
					}
				}}
				placeholder="Suche"
			/>
		</div>
	);
};

export default withStyles(styles)(SearchInput);
