import React, { Component } from 'react';
import { Text, Group } from 'react-konva';
import { Seat } from '..';
import { Text as TextNode } from 'konva/types/shapes/Text';

interface ISeatData {
	id?: number;
	index: number;
	taken?: boolean;
	highlighted?: boolean;
	activated?: boolean;
	disabled?: boolean;
}

interface ISeatRowProps {
	id: number;
	draggable?: boolean;
	displayName?: string;
	onSeatRowClick?: (id: number) => void;
	onSeatRowDoubleClick?: (id: number) => void;
	onSeatClick?: (id: number) => void;
	onSeatDoubleClick?: (id: number) => void;
	onDragMove?: () => void;
	onDragEnd?: (id: number, x: number, y: number) => void;
	seatData: ISeatData[];
	fillFrom: 'center' | 'left' | 'right';
	x: number;
	y: number;
	rotation?: number;
	selected?: boolean;
	disabled?: boolean;
}

export default class SeatRow extends Component<ISeatRowProps, { leftDistance: number }> {
	constructor(props: ISeatRowProps) {
		super(props);
		this.leftTextNode = null;
	}

	leftTextNode: TextNode | null;

	static defaultProps = {
		x: 0,
		y: 0,
		fillFrom: 'left',
		seatData: [],
		draggable: false,
		onSeatClick: () => {},
		onSeatRowDoubleClick: () => {},
		displayName: '',
	};

	state = {
		leftDistance: 5,
	};

	handleSeatRowClick = () => {
		const { onSeatRowClick, id } = this.props;
		if (onSeatRowClick) {
			onSeatRowClick(id);
		}
	};

	handleSeatRowDoubleClick = () => {
		const { onSeatRowDoubleClick, id } = this.props;
		if (onSeatRowDoubleClick) {
			onSeatRowDoubleClick(id);
		}
	};

	componentDidMount() {
		if (this.leftTextNode) {
			this.setState({
				leftDistance: this.leftTextNode.getTextWidth(),
			});
		}
	}

	render() {
		const {
			x,
			y,
			draggable,
			seatData,
			onSeatClick,
			onSeatDoubleClick,
			onDragEnd,
			displayName,
			fillFrom,
			id,
			selected,
			rotation,
			disabled,
		} = this.props;
		const seatArray = seatData
			.sort(fillFrom !== 'right' ? (a, b) => a.index - b.index : (a, b) => b.index - a.index)
			.map((seat: ISeatData, index: number) => {
				return (
					<Seat
						key={seat.id}
						id={seat.id}
						x={index * 10}
						y={0}
						onClick={onSeatClick}
						onDoubleClick={onSeatDoubleClick}
						taken={seat.taken}
						activated={seat.activated}
						disabled={seat.disabled}
						highlighted={seat.highlighted}
					/>
				);
			});
		return (
			<Group
				draggable={draggable}
				onDragEnd={e => {
					if (onDragEnd) {
						onDragEnd(id, e.target.x(), e.target.y());
					}
				}}
				x={x}
				y={y}
				rotation={rotation}
				opacity={disabled ? 0.6 : 1}
			>
				{fillFrom !== 'left' && (
					<Text
						text={displayName}
						x={-2.5 - this.state.leftDistance}
						y={2.5}
						onClick={this.handleSeatRowClick}
						onDblClick={this.handleSeatRowDoubleClick}
						fontSize={5}
						fontFamily="Poppins"
						fill={selected ? 'orange' : 'black'}
						ref={node => (this.leftTextNode = node)}
					/>
				)}
				{seatArray}
				{fillFrom !== 'right' && (
					<Text
						text={displayName}
						x={2.5 + seatData.length * 10}
						y={2.5}
						onClick={this.handleSeatRowClick}
						onDblClick={this.handleSeatRowDoubleClick}
						fontSize={5}
						fontFamily="Poppins"
						fill={selected ? 'orange' : 'black'}
					/>
				)}
			</Group>
		);
	}
}
