export default {
	displayName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
	x: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: 100,
			lessThanOrEqual: 2000,
		},
	},
	y: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: 100,
			lessThanOrEqual: 2000,
		},
	},
};
