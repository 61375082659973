import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, palette }: Theme) => {
	return createStyles({
		root: {
			padding: spacing(3),
		},
		content: {
			alignItems: 'center',
			display: 'flex',
		},
		title: {
			color: palette.text.secondary,
			fontWeight: 700,
		},
		value: {
			marginTop: spacing(1),
		},
		iconWrapper: {
			alignItems: 'center',
			// @ts-ignore: Type is not assignable
			backgroundColor: palette.danger.main,
			borderRadius: '50%',
			display: 'inline-flex',
			height: '4rem',
			justifyContent: 'center',
			marginLeft: 'auto',
			width: '4rem',
		},
		icon: {
			color: palette.common.white,
			fontSize: '2rem',
			height: '2rem',
			width: '2rem',
		},
		footer: {
			marginTop: spacing(2),
			display: 'flex',
			alignItems: 'center',
		},
		differenceNegative: {
			alignItems: 'center',
			// @ts-ignore: Type is not assignable
			color: palette.danger.dark,
			display: 'inline-flex',
			fontWeight: 700,
		},
		differencePositive: {
			alignItems: 'center',
			// @ts-ignore: Type is not assignable
			color: palette.success.dark,
			display: 'inline-flex',
			fontWeight: 700,
		},
		caption: {
			marginLeft: spacing(1),
		},
	});
};

export default styles;
