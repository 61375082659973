import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, breakpoints }: Theme) => {
	return createStyles({
		content: {
			padding: spacing(1),
		},
		row: {
			display: 'flex',
			alignItems: 'center',
		},
		spacer: {
			flexGrow: 1,
		},
		portlet: {
			[breakpoints.down('sm')]: {
				borderRadius: 0,
			},
		},
		toolbar: {
			borderBottomWidth: '1px',
			borderBottomColor: '#DFE3E8',
			borderBottomStyle: 'solid',
			padding: spacing(1),
		},
		tableRow: {
			height: '64px',
		},
		tableCell: {
			whiteSpace: 'nowrap',
		},
		tableCellInner: {
			display: 'flex',
			alignItems: 'center',
		},
		avatarCell: {
			paddingRight: '0px',
		},
		avatar: {
			fontSize: '14px',
			fontWeight: 500,
			height: '36px',
			width: '36px',
			borderRadius: '18px',
		},
		nameText: {
			display: 'inline-block',
			fontWeight: 500,
			cursor: 'pointer',
		},
	});
};

export default styles;
