import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles } from '@material-ui/core';

// Material components
import { IconButton, Popover, Badge } from '@material-ui/core';

// Material icons
import { NotificationsOutlined as NotificationsIcon } from '@material-ui/icons';

// Component styles
import styles from './styles';
import { NotificationList } from './components';

interface INotificationIconTabProps extends WithStyles<typeof styles>, RouteComponentProps {
	className?: string;
}

interface INotificationIconTabState {
	iconEl: HTMLElement | null;
	notifications: any[];
	notificationsLimit: number;
	notificationsCount: number;
}

class NotificationIconTab extends Component<INotificationIconTabProps, INotificationIconTabState> {
	state = {
		iconEl: null,
		notificationsLimit: 4,
		notificationsCount: 2,
		notifications: [],
	};

	handleShowNotifications = (e: React.MouseEvent) => {
		this.setState({
			iconEl: e.currentTarget as HTMLElement,
		});
	};

	handleCloseNotifications = () => {
		this.setState({
			iconEl: null,
		});
	};

	render() {
		const { classes, className } = this.props;
		const { iconEl, notificationsCount, notifications } = this.state;

		const rootClassName = classNames(classes.iconButton, className);
		const showPopover = Boolean(iconEl);

		return (
			<Fragment>
				<IconButton className={rootClassName} onClick={this.handleShowNotifications}>
					<Badge badgeContent={notificationsCount} color="primary" variant="dot">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<Popover
					anchorEl={iconEl}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					onClose={this.handleCloseNotifications}
					open={showPopover}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
				>
					<NotificationList notifications={notifications} />
				</Popover>
			</Fragment>
		);
	}
}

export default withStyles(styles)(withRouter<INotificationIconTabProps>(NotificationIconTab));
