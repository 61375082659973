import React, { Component } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	Avatar,
	LinearProgress,
	SnackbarContent,
} from '@material-ui/core';
import styles from './styles';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
	getProjectGeneralSettings,
	getProjectGeneralSettingsVariables,
} from '__schema__/getProjectGeneralSettings';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import validate from 'validate.js';
import schema from './schema';
import { editProjectVariables, editProject } from '__schema__/editProject';

const GET_PROJECT_GENERAL_SETTINGS = gql`
	query getProjectGeneralSettings($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			identifier
			displayName
			image
		}
	}
`;

const EDIT_PROJECT_GENERAL_SETTINGS = gql`
	mutation editProject($id: Int!, $displayName: String!, $image: String) {
		editProject(id: $id, displayName: $displayName, image: $image) {
			id
			identifier
			displayName
			image
		}
	}
`;

interface IGeneralSettingsProps extends WithStyles<typeof styles> {
	projectIdentifier: string;
}

class GeneralSettings extends Component<IGeneralSettingsProps, {}> {
	render() {
		const { classes, projectIdentifier } = this.props;
		return (
			<Query<getProjectGeneralSettings, getProjectGeneralSettingsVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_GENERAL_SETTINGS}
			>
				{({ data }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<Mutation<editProject, editProjectVariables> mutation={EDIT_PROJECT_GENERAL_SETTINGS}>
								{(updateProject, { error }) => {
									return (
										<Formik
											initialValues={{
												identifier: data.getProjectByIdentifier.identifier,
												displayName: data.getProjectByIdentifier.displayName,
												image:
													data.getProjectByIdentifier.image != null
														? data.getProjectByIdentifier.image
														: undefined,
											}}
											validate={values => {
												return validate(values, schema);
											}}
											onSubmit={async (values, { setSubmitting }) => {
												try {
													const result = await updateProject({
														variables: { id: data.getProjectByIdentifier.id, ...values },
													});
													if (result != null && result.data != null && result.data.editProject != null) {
														setSubmitting(false);
													} else {
														setSubmitting(false);
													}
												} catch {
													setSubmitting(false);
												}
											}}
											render={props => (
												<Portlet className={classes.portlet}>
													<PortletHeader>
														<Typography variant="h4">Allgemeine Einstellungen</Typography>
													</PortletHeader>
													<PortletContent>
														<Form>
															<Grid container spacing={2}>
																<Grid item xs={12}>
																	<Typography color="primary" variant="h5">
																		Projekt
																	</Typography>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Typography variant="h6">Identifier</Typography>
																	<Typography variant="body2">
																		Der Identifier zur Identifizierung eines Projektes. Dieser kann nach
																		Erstellung des Projektes nicht mehr verändert werden
																	</Typography>
																	<Field
																		className={classes.fullWidthField}
																		name="identifier"
																		disabled
																		label="Identifier"
																		variant="outlined"
																		component={TextField}
																	/>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Typography variant="h6">Anzeigename</Typography>
																	<Typography variant="body2">
																		Der Anzeigename der anstatt des Identifiers angezeigt werden soll, solange
																		dies möglich ist
																	</Typography>
																	<Field
																		className={classes.fullWidthField}
																		name="displayName"
																		label="Anzeigename"
																		variant="outlined"
																		component={TextField}
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Typography color="primary" variant="h5">
																		Personalisierung
																	</Typography>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Typography variant="h6">Bild</Typography>
																	<Typography variant="body2">
																		Bild zur besseren Identifizierung des Projektes in Listen und im
																		Dashboard, sowie zur Personalisierung des Projektes
																	</Typography>
																	<Field
																		className={classes.fullWidthField}
																		name="image"
																		label="Bild"
																		variant="outlined"
																		component={TextField}
																	/>
																</Grid>
																<Grid item xs={12} md={6}>
																	<Typography variant="h6">Derzeitiges Bild</Typography>
																	<Avatar
																		alt="Roman Kutepov"
																		className={classes.avatar}
																		src={
																			data.getProjectByIdentifier.image == null
																				? undefined
																				: data.getProjectByIdentifier.image
																		}
																	/>
																</Grid>
															</Grid>
														</Form>
													</PortletContent>
													{props.isSubmitting && <LinearProgress />}
													{error && <SnackbarContent message={error.message} />}
													<PortletFooter>
														<div className={classes.row}>
															<span className={classes.spacer} />
															<Button onClick={props.submitForm} color="primary" variant="contained">
																Einstellungen speichern
															</Button>
														</div>
													</PortletFooter>
												</Portlet>
											)}
										/>
									);
								}}
							</Mutation>
						);
					}
					return null;
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(GeneralSettings);
