import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing }: Theme) => {
	return createStyles({
		root: {
			padding: 0,
		},
		tabs: {
			marginBottom: 0,
			borderRadius: 0,
		},
		content: {
			padding: spacing(2),
		},
		details: {
			paddingTop: spacing(0.5),
			paddingLeft: spacing(2),
		},
		idText: {
			paddingLeft: spacing(0.5),
		},
		row: {
			display: 'flex',
			alignItems: 'baseline',
		},
		spacer: {
			flexGrow: 1,
		},
		tab: {
			paddingRight: '20px',
			paddingLeft: '20px',
		},
	});
};

export default styles;
