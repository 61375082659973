import React from 'react';
import ReactDOM from 'react-dom';

// Externals
import { Chart } from 'react-chartjs-2';

// ChartJS helpers
import { chartjs } from './helpers';

// Service worker
import * as serviceWorker from './common/serviceWorker';

// App
import App from './App';

// Configure ChartJS
Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
	draw: chartjs.draw,
});

ReactDOM.render(React.createElement(App), document.getElementById('root'));

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		ReactDOM.render(React.createElement(NextApp), document.getElementById('root'));
	});
}

serviceWorker.register();
