import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing }: Theme) => {
	return createStyles({
		row: {
			display: 'flex',
			alignItems: 'center',
		},
		spacer: {
			flexGrow: 1,
		},
	});
};

export default styles;
