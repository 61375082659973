import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles, Avatar, Grid } from '@material-ui/core';

// Material components
import {
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@material-ui/core';

// Material icons
import {
	ArrowForwardIos as ArrowForwardIosIcon,
	Settings as SettingsIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';

interface IUserTabDetailsProps extends WithStyles<typeof styles> {
	className: string;
}

class UserTabDetails extends Component<IUserTabDetailsProps, {}> {
	render() {
		const { className, classes } = this.props;

		const rootClassName = classNames(classes.root, className);

		return (
			<div className={rootClassName}>
				<div className={classes.header}>
					<Grid
						container
						direction="row"
						justify="flex-start"
						alignItems="center">
						<Avatar
							className={classes.avatar}
							alt="Matthias Pompe"
							src="https://pbs.twimg.com/profile_images/469017630796296193/R-bEN4UP.png"
						/>
						<div>
							<Typography className={classes.title} variant="h6">
								Matthias Pompe
							</Typography>
							<Typography className={classes.subtitle} variant="body2">
								matthias_pompe@gmx.de
							</Typography>
						</div>
					</Grid>
				</div>
				<div className={classes.content}>
					<List component="div">
						<Link to="#">
							<ListItem
								className={classes.listItem}
								component="div"
								onClick={() => {}}>
								<ListItemIcon className={classes.listItemIcon}>
									<SettingsIcon />
								</ListItemIcon>
								<ListItemText
									classes={{ secondary: classes.listItemTextSecondary }}
									primary={'Accounteinstellungen'}
									secondary={'Alle Einstellungen für den Account'}
								/>
								<ArrowForwardIosIcon className={classes.arrowForward} />
							</ListItem>
							<Divider />
						</Link>
					</List>
				</div>
			</div>
		);
	}

	static defaultProps = {
		notifications: [],
		onSelect: () => {},
		className: '',
	};
}

export default withStyles(styles)(UserTabDetails);
