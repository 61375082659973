import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as queryString from 'query-string';

// Views
import Dashboard from './views/Dashboard';
import ShowList from './views/ShowList';
import UserList from './views/UserList';
import Typography from './views/Typography';
import Icons from './views/Icons';
import Account from './views/Account';
import Settings from './views/Settings';
import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';
import ProjectList from 'views/ProjectList';
import AuthRoute from 'helpers/authRoute';
import { LoadingScreen } from 'components';
import ProjectSettings from 'views/ProjectSettings';
import { DashboardLayout } from 'layouts';
import ShowDetails from 'views/ShowDetails';
import CreateReservation from 'views/CreateReservation';
import ReservationList from 'views/ReservationList';
import ReservationDetails from 'views/ReservationDetails';

export default class Routes extends Component {
	render() {
		return (
			<Switch>
				<Redirect exact from="/" to="/projects" />
				<Route component={SignUp} exact path="/sign-up" />
				<Route component={SignIn} exact path="/sign-in" />
				<Route component={LoadingScreen} exact path="/loading" />
				<AuthRoute component={ProjectList} exact path="/projects" />
				<AuthRoute
					path="/p/:identifier"
					render={({ match: { path, params } }) => (
						<DashboardLayout title={'Projekteinstellungen'}>
							<Switch>
								<Route path={`${path}/dashboard`} component={Dashboard} exact />
								<Route
									path={`${path}/show`}
									render={props => <ShowList {...props} projectIdentifier={params.identifier} />}
									exact
								/>
								<Route
									path={`${path}/show/:id/:key`}
									render={props => (
										<ShowDetails
											key={props.match.params.id}
											{...props}
											showId={Number(props.match.params.id)}
											startTab={props.match.params.key}
										/>
									)}
									exact
								/>
								<Redirect from={`${path}/show/:id/`} to={`${path}/show/:id/overview`} exact />
								<Route path={`${path}/settings/:key`} component={ProjectSettings} exact />
								<Route path={`${path}/settings/:key/:subKey`} component={ProjectSettings} exact />
								<Route
									path={`${path}/reservation/create`}
									render={props => (
										<CreateReservation
											{...props}
											projectIdentifier={props.match.params.identifier}
											initialValues={queryString.parse(props.location.search)}
										/>
									)}
									exact
								/>
								<Route
									path={`${path}/reservation/edit/:id`}
									render={props => (
										<CreateReservation
											{...props}
											projectIdentifier={props.match.params.identifier}
											editMode
											reservationId={Number(props.match.params.id)}
											initialValues={queryString.parse(props.location.search)}
										/>
									)}
									exact
								/>
								<Route
									path={`${path}/reservation/:id/:key`}
									render={props => (
										<ReservationDetails
											key={props.match.params.id}
											{...props}
											reservationId={Number(props.match.params.id)}
											startTab={props.match.params.key}
										/>
									)}
									exact
								/>
								<Redirect from={`${path}/reservation/:id/`} to={`${path}/reservation/:id/overview`} exact />
								<Route
									path={`${path}/reservation`}
									render={props => (
										<ReservationList {...props} projectIdentifier={props.match.params.identifier} />
									)}
									exact
								/>
								<Route path={`${path}/reservation/edit/:id`} component={CreateReservation} exact />
								<Redirect from={`${path}/settings/`} to={`${path}/settings/general`} exact />
								<Redirect to="/not-found" />
							</Switch>
						</DashboardLayout>
					)}
				/>
				<AuthRoute component={Dashboard} exact path="/dashboard" />
				<AuthRoute component={UserList} exact path="/users" />
				<AuthRoute component={Typography} exact path="/typography" />
				<AuthRoute component={Icons} exact path="/icons" />
				<AuthRoute component={Account} exact path="/account" />
				<AuthRoute component={Settings} exact path="/settings" />
				<AuthRoute component={UnderDevelopment} exact path="/under-development" />
				<AuthRoute component={NotFound} exact path="/not-found" />
				<Redirect to="/not-found" />
			</Switch>
		);
	}
}
