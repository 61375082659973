import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
	Grid,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { withRouter, RouteComponentProps } from 'react-router';
import { CreateAudienceSpace, CreateAudienceSpaceVariables } from '__schema__/CreateAudienceSpace';

const CREATE_AUDIENCESPACE = gql`
	mutation CreateAudienceSpace($projectId: Int!, $displayName: String!, $x: Int!, $y: Int!) {
		createAudienceSpace(
			createAudienceSpaceData: { projectId: $projectId, displayName: $displayName, x: $x, y: $y }
		) {
			id
			displayName
		}
	}
`;

interface ICreateAudienceSpaceDialogProps
	extends WithMobileDialog,
		WithStyles<typeof styles>,
		RouteComponentProps {
	open: boolean;
	projectId: number;
	projectIdentifier: string;
	handleClose?: () => void;
	handleCreated?: () => void;
}

const CreateAudienceSpaceDialog: React.FC<ICreateAudienceSpaceDialogProps> = function(
	props: ICreateAudienceSpaceDialogProps,
) {
	const {
		fullScreen,
		open,
		handleClose,
		classes,
		history,
		projectIdentifier,
		projectId,
		handleCreated,
	} = props;

	return (
		<Mutation<CreateAudienceSpace, CreateAudienceSpaceVariables> mutation={CREATE_AUDIENCESPACE}>
			{(createAudienceSpace, { error }) => {
				return (
					<Formik
						initialValues={{ displayName: '', x: 200, y: 200 }}
						validate={values => {
							return validate(values, schema);
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const result = await createAudienceSpace({
									variables: { ...values, x: Number(values.x), y: Number(values.y), projectId: projectId },
								});
								if (result != null && result.data != null && result.data.createAudienceSpace != null) {
									setSubmitting(false);
									if (handleCreated) {
										handleCreated();
									}
									history.push(
										`/p/${projectIdentifier}/settings/audiencespace/${result.data.createAudienceSpace.id}`,
									);
								} else {
									setSubmitting(false);
								}
							} catch {
								setSubmitting(false);
							}
						}}
						render={props => (
							<Dialog
								fullScreen={fullScreen}
								open={open}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">Neuen Zuschauerraum erstellen</DialogTitle>
								<DialogContent>
									<DialogContentText>
										Erstelle einen neuen Zuschauerraum, der durch seinen Anzeigename, als auch Größe definiert
										wird. Beides kann nachträglich noch verändert werden
									</DialogContentText>
									<Form>
										<Field
											className={classes.fullWidthField}
											name="displayName"
											label="Anzeigename"
											variant="outlined"
											component={TextField}
										/>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Field
													className={classes.fullWidthField}
													name="x"
													label="X (Breite)"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
											<Grid item xs={6}>
												<Field
													className={classes.fullWidthField}
													name="y"
													label="Y (Länge)"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
										</Grid>
									</Form>
									{error && <SnackbarContent message={error.message} />}
								</DialogContent>
								{props.isSubmitting && <LinearProgress />}
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Abbrechen
									</Button>
									<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
										Zuschauerraum erstellen
									</Button>
								</DialogActions>
							</Dialog>
						)}
					/>
				);
			}}
		</Mutation>
	);
};

export default withMobileDialog()(
	withStyles(styles)(withRouter<ICreateAudienceSpaceDialogProps>(CreateAudienceSpaceDialog)),
);
