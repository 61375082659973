export default {
	displayName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
	date: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
	},
	audienceSpace: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
		},
	},
};
