export default {
	identifier: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			minimum: 4,
			maximum: 64,
			message: 'muss zwischen 4 und 64 Charakteren beinhalten',
		},
	},
	displayName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			maximum: 128,
			message: 'darf maximal 128 Charakteren beinhalten',
		},
	},
};
