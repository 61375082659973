export default {
	firstName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			minimum: 2,
			maximum: 64,
			message: 'muss zwischen 2 und 64 Charakteren beinhalten',
		},
	},
	lastName: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		length: {
			minimum: 2,
			maximum: 128,
			message: 'darf zwischen 2 und 128 Charakteren beinhalten',
		},
	},
	email: {
		email: true,
	},
	seatCount: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
			greaterThan: 0,
			lessThan: 21,
		},
	},
	showId: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
		},
	},
	reservationPriorityId: {
		presence: { allowEmpty: false, message: 'wird benötigt' },
		numericality: {
			onlyInteger: true,
		},
	},
};
