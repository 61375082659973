import React, { Component, Fragment } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, withWidth, WithStyles } from '@material-ui/core';

// Material components
import { Drawer } from '@material-ui/core';

// Custom components
import { Sidebar, Topbar } from './components';

// Component styles
import styles from './styles';
import { WithWidth } from '@material-ui/core/withWidth';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getProjectDetailsVariables, getProjectDetails } from '__schema__/getProjectDetails';
import { withRouter, RouteComponentProps } from 'react-router';
import { LoadingScreen } from 'components';

const GET_PROJECT = gql`
	query getProjectDetails($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			displayName
			identifier
			image
		}
	}
`;

interface RouteParams {
	identifier: string;
}

interface IDashboardProps extends WithWidth, WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	children?: React.ReactNode;
	className?: string;
	title: string;
}

interface IDashboardState {
	isOpen: boolean;
}

class DashboardLayout extends Component<IDashboardProps, IDashboardState> {
	constructor(props: IDashboardProps) {
		super(props);

		const isMobile = ['xs', 'sm', 'md'].includes(props.width);

		this.state = {
			isOpen: !isMobile,
		};
	}

	handleClose = () => {
		this.setState({ isOpen: false });
	};

	handleToggleOpen = () => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	render() {
		const { classes, width, title, children, match } = this.props;
		const { isOpen } = this.state;

		const isMobile = ['xs', 'sm', 'md'].includes(width);
		const shiftTopbar = isOpen && !isMobile;
		const shiftContent = isOpen && !isMobile;

		return (
			<Query<getProjectDetails, getProjectDetailsVariables>
				variables={{ identifier: match.params.identifier }}
				query={GET_PROJECT}
			>
				{({ data }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<Fragment>
								<Topbar
									className={classNames(classes.topbar, {
										[classes.topbarShift]: shiftTopbar,
									})}
									isSidebarOpen={isOpen}
									onToggleSidebar={this.handleToggleOpen}
									title={title}
								/>
								<Drawer
									anchor="left"
									classes={{ paper: classes.drawerPaper }}
									onClose={this.handleClose}
									open={isOpen}
									variant={isMobile ? 'temporary' : 'persistent'}
								>
									<Sidebar
										className={classes.sidebar}
										projectIdentifier={data.getProjectByIdentifier.identifier}
										projectDisplayName={data.getProjectByIdentifier.displayName}
										projectImage={
											data.getProjectByIdentifier.image == null
												? undefined
												: data.getProjectByIdentifier.image
										}
									/>
								</Drawer>
								<main
									className={classNames(classes.content, {
										[classes.contentShift]: shiftContent,
									})}
								>
									{children}
								</main>
							</Fragment>
						);
					} else {
						return <LoadingScreen />;
					}
				}}
			</Query>
		);
	}
}

export default withRouter(withWidth()(withStyles(styles)(DashboardLayout)));
