import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing, typography }: Theme) => {
	return createStyles({
		root: {
			padding: spacing(3),
			minHeight: 780,
		},
		content: {
			marginTop: spacing(2),
			maxWidth: 1000,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		progressWrapper: {
			paddingTop: '48px',
			paddingBottom: '24px',
			display: 'flex',
			justifyContent: 'center',
		},
		card: {
			minWidth: 250,
			height: 200,
			borderRadius: spacing(2),
		},
		cardContent: {
			minHeight: 150,
			flex: 1,
		},
		introduction: {
			marginBottom: spacing(3),
		},
		subIntroduction: {
			maxWidth: 500,
			marginBottom: spacing(2),
		},
		introductionButton: {
			marginLeft: spacing(1),
		},
		headline: {
			marginBottom: spacing(1),
		},
		avatar: {
			width: typography.pxToRem(70),
			height: typography.pxToRem(70),
			marginBottom: spacing(2),
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		center: {
			marginLeft: 'auto',
			marginRight: 'auto',
			textAlign: 'center',
			marginBottom: '0px',
		},
		addIcon: {
			width: typography.pxToRem(50),
			height: typography.pxToRem(50),
		},
	});
};

export default styles;
