import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => {
	return createStyles({
		stageDiv: {
			width: '100%',
		},
	});
};

export default styles;
