import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Material helpers
import { ThemeProvider } from '@material-ui/styles';

// Apollo
import { ApolloProvider } from 'react-apollo';
import client from 'helpers/apolloClient';

// Theme
import theme from './theme';

// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// Routes
import Routes from './Routes';

// Notistack
import { SnackbarProvider } from 'notistack';

// Browser history
const browserHistory = createBrowserHistory();

export default class App extends Component<{}, {}> {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<ApolloProvider client={client}>
					<SnackbarProvider maxSnack={3}>
						<Router history={browserHistory}>
							<Routes />
						</Router>
					</SnackbarProvider>
				</ApolloProvider>
			</ThemeProvider>
		);
	}
}
