import React, { Component } from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { withStyles, WithStyles, LinearProgress, Chip } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Material icons
import { EventSeatOutlined as SeatIcon, LocationOnOutlined as LocationIcon } from '@material-ui/icons';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

interface IShowCardProps extends WithStyles<typeof styles> {
	className?: string;
	show: {
		id: number;
		displayName: string;
		maxSeats: number;
		takenSeats: number;
		date: Date;
		locationName: string;
	};
}

class ShowCard extends Component<IShowCardProps, {}> {
	render() {
		const { classes, className, show } = this.props;
		const pastTime = Date.now() - show.date.valueOf();

		const rootClassName = classNames(classes.root, className);

		return (
			<Paper className={rootClassName}>
				<Chip
					color={pastTime > 1 ? 'secondary' : 'primary'}
					label={pastTime > 1 ? 'Vergangene Show' : 'Bevorstehende Show'}
					className={classes.chip}
					variant="outlined"
				/>
				{/* <div className={classes.imageWrapper}>
					<Avatar className={classes.image} style={{ backgroundColor: '#333333' }}>
						25.6
					</Avatar>
				</div> */}
				<div className={classes.details}>
					<Typography className={classes.title} variant="h4">
						{show.displayName}
					</Typography>
					<Typography className={classes.description} variant="body1">
						{show.date.toLocaleString()}
					</Typography>
				</div>
				<LinearProgress
					variant="determinate"
					color={(show.takenSeats / show.maxSeats) * 100 >= 100 ? 'secondary' : 'primary'}
					value={(show.takenSeats / show.maxSeats) * 100}
				/>
				<div className={classes.stats}>
					<LocationIcon className={classes.updateIcon} />
					<Typography className={classes.updateText} variant="body2">
						{show.locationName}
					</Typography>
					<SeatIcon className={classes.downloadsIcon} />
					<Typography className={classes.downloadsText} variant="body2">
						{show.maxSeats - show.takenSeats} Plätze übrig
					</Typography>
				</div>
			</Paper>
		);
	}
}

export default withStyles(styles)(ShowCard);
