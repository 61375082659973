import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable, Operation } from 'apollo-link';

// Apollo Client
const cache = new InMemoryCache();

const request = async (operation: Operation) => {
	const token = window.localStorage.getItem('token');
	operation.setContext({
		headers: {
			authorization: token ? `Bearer ${token}` : '',
			'Access-Control-Allow-Origin': window.location.host.includes('ticketos')
				? 'http://ticketos.io:5000'
				: 'https://api.resyotic.com',
		},
	});
};

const requestLink = new ApolloLink(
	(operation, forward) =>
		new Observable(observer => {
			let handle: ZenObservable.Subscription;
			Promise.resolve(operation)
				.then(oper => request(oper))
				.then(() => {
					if (forward) {
						handle = forward(operation).subscribe({
							next: observer.next.bind(observer),
							error: observer.error.bind(observer),
							complete: observer.complete.bind(observer),
						});
					}
				})
				.catch(observer.error.bind(observer));
			return () => {
				if (handle) handle.unsubscribe();
			};
		}),
);

const client = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors) {
				console.log(graphQLErrors);
			}
			if (networkError) {
				console.log(networkError);
			}
		}),
		requestLink,
		withClientState({
			defaults: {
				isConnected: true,
			},
			resolvers: {
				Mutation: {
					updateNetworkStatus: (_: any, data: { isConnected: boolean }, apollo: { cache: InMemoryCache }) => {
						cache.writeData({ data: { isConnected: data.isConnected } });
						return null;
					},
				},
			},
			cache,
		}),
		new BatchHttpLink({
			uri: window.location.host.includes('ticketos')
				? 'http://ticketos.io:5000/graphql'
				: 'https://api.resyotic.com/graphql',
			credentials: 'include',
		}),
	]),
	cache,
});

export default client;
