import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => {
	return createStyles({
		root: {
			width: '350px',
			maxWidth: '100%',
		},
		header: {
			backgroundColor: theme.palette.background.default,
			backgroundImage: 'url("/images/animated-svg.gif")',
			backgroundPositionX: 'right',
			backgroundPositionY: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			paddingBottom: '34px',
			paddingLeft: '20px',
			paddingRight: '20px',
			paddingTop: '34px',
			color: theme.palette.common.white,
		},
		subtitle: {
			color: theme.palette.common.white,
		},
		title: {
			color: theme.palette.common.white,
		},
		listItem: {
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: theme.palette.background.default,
			},
		},
		listItemIcon: {
			backgroundColor: 'rgba(0, 0, 0, 0.03)',
			padding: '10px',
			borderRadius: '50%',
			color: theme.palette.primary.main,
			marginRight: theme.spacing(2),
		},
		listItemTextSecondary: {
			marignTop: '4px',
			color: theme.palette.text.secondary,
		},
		arrowForward: {
			color: theme.palette.text.secondary,
			height: '16px',
			width: '16px',
		},
		content: {},
		footer: {
			paddingBottom: theme.spacing(1),
			display: 'flex',
			justifyContent: 'center',
		},
		avatar: {
			marginRight: '10px',
			height: theme.typography.pxToRem(theme.typography.fontSize * 3),
			width: theme.typography.pxToRem(theme.typography.fontSize * 3),
		},
	});
};

export default styles;
