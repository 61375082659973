import { Theme, createStyles } from '@material-ui/core';

const styles = ({ palette, spacing }: Theme) => {
	return createStyles({
		root: {
			padding: spacing(3),
		},
		content: {
			marginTop: spacing(2),
		},
		progressWrapper: {
			paddingTop: '48px',
			paddingBottom: '24px',
			display: 'flex',
			justifyContent: 'center',
		},
	});
};

export default styles;
