import React, { Component } from 'react';
import { withStyles, WithStyles, CircularProgress } from '@material-ui/core';

import styles from './styles';

class LoadingView extends Component<WithStyles<typeof styles>, {}> {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<div>
					<CircularProgress />
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(LoadingView);
