import React, { Component } from 'react';
import { Portlet, PortletHeader, PortletContent, PortletFooter } from 'components';
import {
	Typography,
	Button,
	withStyles,
	WithStyles,
	Grid,
	Avatar,
	LinearProgress,
	SnackbarContent,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	OutlinedInput,
	Select,
	MenuItem,
} from '@material-ui/core';
import styles from './styles';
import { Query, Mutation, withApollo, WithApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import validate from 'validate.js';
import schema from './schema';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getProjectMembersVariables, getProjectMembers } from '__schema__/getProjectMembers';
import { addUserToProjectVariables, addUserToProject } from '__schema__/addUserToProject';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { promoteProjectMember, promoteProjectMemberVariables } from '__schema__/promoteProjectMember';
import { ProjectMemberRank } from '__schema__/globalTypes';

const GET_PROJECT_MEMBERS = gql`
	query getProjectMembers($identifier: String!) {
		getProjectByIdentifier(identifier: $identifier) {
			id
			members {
				id
				rank
				joinedAt
				user {
					auth0Id
					fullName
					name
					nickname
					profilePicture
					email
				}
			}
		}
	}
`;

const ADD_PROJECT_MEMBER = gql`
	mutation addUserToProject($projectId: Int!, $email: String!) {
		addUserToProject(projectId: $projectId, email: $email) {
			id
			rank
			user {
				auth0Id
				fullName
				name
				nickname
				profilePicture
				email
			}
		}
	}
`;

const PROMOTE_PROJECT_MEMBER = gql`
	mutation promoteProjectMember($id: Int!, $rank: ProjectMemberRank!) {
		promoteProjectMember(id: $id, rank: $rank) {
			id
			rank
		}
	}
`;

interface IMemberSettingsProps extends WithStyles<typeof styles>, WithSnackbarProps {
	projectIdentifier: string;
}

class MemberSettings extends Component<WithApolloClient<IMemberSettingsProps>, {}> {
	sendMutationPromoteProjectMember = async (id: number, rank: ProjectMemberRank) => {
		const { client, enqueueSnackbar } = this.props;
		try {
			await client.mutate<promoteProjectMember, promoteProjectMemberVariables>({
				mutation: PROMOTE_PROJECT_MEMBER,
				variables: { id, rank },
			});
		} catch {
			enqueueSnackbar('Fehler beim Ändern des Ranges aufgetreten', { variant: 'error' });
		}
	};

	render() {
		const { classes, projectIdentifier } = this.props;
		return (
			<Query<getProjectMembers, getProjectMembersVariables>
				variables={{ identifier: projectIdentifier }}
				query={GET_PROJECT_MEMBERS}
			>
				{({ data, refetch }) => {
					if (data && data.getProjectByIdentifier) {
						return (
							<Mutation<addUserToProject, addUserToProjectVariables> mutation={ADD_PROJECT_MEMBER}>
								{(addMemberToProject, { error }) => {
									return (
										<Formik
											initialValues={{
												email: '',
											}}
											validate={values => {
												return validate(values, schema);
											}}
											onSubmit={async (values, { setSubmitting }) => {
												try {
													const result = await addMemberToProject({
														variables: { projectId: data.getProjectByIdentifier.id, ...values },
													});
													if (result != null && result.data != null && result.data.addUserToProject != null) {
														setSubmitting(false);
														refetch();
													} else {
														setSubmitting(false);
														refetch();
													}
												} catch {
													setSubmitting(false);
												}
											}}
											render={props => (
												<Portlet className={classes.portlet}>
													<PortletHeader>
														<Typography variant="h4">Mitglieder</Typography>
													</PortletHeader>
													<PortletContent noPadding>
														<Form>
															<Grid container>
																<Grid className={classes.toolbar} item xs={12}>
																	<Grid spacing={2} container justify="space-between" alignItems="center">
																		<Grid item xs={8} md={10}>
																			<Field
																				fullWidth
																				name="email"
																				label="E-Mail"
																				variant="outlined"
																				margin="dense"
																				component={TextField}
																			/>
																		</Grid>
																		<Grid item xs={4} md={2}>
																			<Button
																				onClick={props.submitForm}
																				size="large"
																				color="primary"
																				variant="contained"
																				fullWidth
																			>
																				Mitglied hinzufügen
																			</Button>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item xs={12}>
																	{props.isSubmitting && <LinearProgress />}
																	{error && <SnackbarContent message={error.message} />}
																</Grid>
																<Grid item xs={12}>
																	<PerfectScrollbar>
																		<Table>
																			<TableHead>
																				<TableRow>
																					<TableCell className={classes.avatarCell} align="left" />
																					<TableCell align="left">Name</TableCell>
																					<TableCell align="left">Auth0ID</TableCell>
																					<TableCell align="left">E-Mail</TableCell>
																					<TableCell align="left">Beigetreten</TableCell>
																					<TableCell align="left">Rang</TableCell>
																				</TableRow>
																			</TableHead>
																			<TableBody>
																				{data.getProjectByIdentifier.members.map(projectMember => (
																					<TableRow
																						className={classes.tableRow}
																						hover
																						key={projectMember.user.auth0Id}
																					>
																						<TableCell className={classes.avatarCell}>
																							<Avatar
																								className={classes.avatar}
																								src={projectMember.user.profilePicture}
																							/>
																						</TableCell>
																						<TableCell className={classes.tableCell}>
																							<Typography className={classes.nameText} variant="body1">
																								{projectMember.user.name}
																							</Typography>
																						</TableCell>
																						<TableCell className={classes.tableCell}>
																							{projectMember.user.auth0Id}
																						</TableCell>
																						<TableCell className={classes.tableCell}>
																							{projectMember.user.email}
																						</TableCell>
																						<TableCell className={classes.tableCell}>
																							{projectMember.joinedAt}
																						</TableCell>
																						<TableCell className={classes.tableCell}>
																							<Select
																								fullWidth
																								value={projectMember.rank}
																								onChange={event => {
																									this.sendMutationPromoteProjectMember(
																										projectMember.id,
																										event.target.value as ProjectMemberRank,
																									);
																								}}
																								input={<OutlinedInput name="age" labelWidth={0} />}
																							>
																								<MenuItem disabled value={'Owner'}>
																									Owner
																								</MenuItem>
																								<MenuItem
																									disabled={projectMember.rank === 'Owner'}
																									value={'Administrator'}
																								>
																									Administrator
																								</MenuItem>
																								<MenuItem
																									disabled={projectMember.rank === 'Owner'}
																									value={'Moderator'}
																								>
																									Moderator
																								</MenuItem>
																								<MenuItem
																									disabled={projectMember.rank === 'Owner'}
																									value={'Editor'}
																								>
																									Editor
																								</MenuItem>
																								<MenuItem
																									disabled={projectMember.rank === 'Owner'}
																									value={'Standard'}
																								>
																									Standard
																								</MenuItem>
																								<MenuItem
																									disabled={projectMember.rank === 'Owner'}
																									value={'Inspector'}
																								>
																									Inspektor
																								</MenuItem>
																							</Select>
																						</TableCell>
																					</TableRow>
																				))}
																			</TableBody>
																		</Table>
																	</PerfectScrollbar>
																</Grid>
															</Grid>
														</Form>
													</PortletContent>
													<PortletFooter>
														<div className={classes.row}>
															<span className={classes.spacer} />
														</div>
													</PortletFooter>
												</Portlet>
											)}
										/>
									);
								}}
							</Mutation>
						);
					}
					return null;
				}}
			</Query>
		);
	}
}

export default withSnackbar(withStyles(styles)(withApollo<IMemberSettingsProps>(MemberSettings)));
