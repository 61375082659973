import { createStyles } from '@material-ui/core';

const styles = () => {
	return createStyles({
		root: {
			height: '100vh',
			width: '100vh',
		},
		center: {
			position: 'absolute',
			left: '50%',
			top: '50%',
			transform: 'translate(-50%, -50%) scale(2)',
		},
	});
};

export default styles;
