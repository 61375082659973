import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { CreateProject, CreateProjectVariables } from '__schema__/CreateProject';
import { withRouter, RouteComponentProps } from 'react-router';

const CREATE_PROJECT = gql`
	mutation CreateProject($identifier: String!, $displayName: String!) {
		createProject(identifier: $identifier, displayName: $displayName) {
			identifier
			displayName
		}
	}
`;

interface ICreateProjectDialogProps extends WithMobileDialog, WithStyles<typeof styles>, RouteComponentProps {
	open: boolean;
	handleClose?: () => void;
}

const CreateProjectDialog: React.FC<ICreateProjectDialogProps> = function(props: ICreateProjectDialogProps) {
	const { fullScreen, open, handleClose, classes, history } = props;

	return (
		<Mutation<CreateProject, CreateProjectVariables> mutation={CREATE_PROJECT}>
			{(createProject, { error }) => {
				return (
					<Formik
						initialValues={{ identifier: '', displayName: '' }}
						validate={values => {
							return validate(values, schema);
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const result = await createProject({ variables: values });
								if (result != null && result.data != null && result.data.createProject != null) {
									setSubmitting(false);
									history.push(`/p/${result.data.createProject.identifier}/dashboard`);
								} else {
									setSubmitting(false);
								}
							} catch {
								setSubmitting(false);
							}
						}}
						render={props => (
							<Dialog
								fullScreen={fullScreen}
								open={open}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">Neues Projekt erstellen</DialogTitle>
								<DialogContent>
									<DialogContentText>
										Erstelle ein neues Projekt, du benötigst dafür einen Identifier, der über ganz TicketOS
										einzigartig sein muss und zur Identifikation des Projektes dient, zusätzlich wird ein
										Anzeigename benötigt, der an jeder möglichen Stelle anstatt des Identifiers angezeigt
										wird.
									</DialogContentText>
									<Form>
										<Field
											className={classes.fullWidthField}
											name="identifier"
											label="Identifier"
											variant="outlined"
											component={TextField}
										/>
										<Field
											className={classes.fullWidthField}
											name="displayName"
											label="Anzeigename"
											variant="outlined"
											component={TextField}
										/>
									</Form>
									{error && <SnackbarContent message={error.message} />}
								</DialogContent>
								{props.isSubmitting && <LinearProgress />}
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Abbrechen
									</Button>
									<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
										Projekt erstellen
									</Button>
								</DialogActions>
							</Dialog>
						)}
					/>
				);
			}}
		</Mutation>
	);
};

export default withMobileDialog()(
	withStyles(styles)(withRouter<ICreateProjectDialogProps>(CreateProjectDialog)),
);
