import { Theme, createStyles } from '@material-ui/core';

const styles = (theme: Theme) => {
	return createStyles({
		root: {
			alignItems: 'center',
			backgroundColor: theme.palette.common.white,
			border: '1px solid #DFE3E8',
			borderRadius: '4px',
			display: 'flex',
			flexBasis: '420px',
			paddingBottom: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			paddingTop: theme.spacing(0.5),
		},
		icon: {
			marginRight: theme.spacing(1),
			color: theme.palette.text.secondary,
		},
		input: {
			flexGrow: 1,
			fontSize: '14px',
			lineHeight: '16px',
			letterSpacing: '-0.05px',
		},
	});
};

export default styles;
