import React, { Component } from 'react';
import { withStyles, WithStyles, Tabs, Tab, Paper, Grid, Typography, Button } from '@material-ui/core';

import styles from './styles';
import { withRouter, RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { getShowGeneralVariables, getShowGeneral } from '__schema__/getShowGeneral';
import { ReservationList, ShowAudienceSpaceView, ShowSettings } from './components';

const GET_SHOW_GENERAL = gql`
	query getShowGeneral($id: Int!) {
		getShowById(id: $id) {
			id
			displayName
			lastUpdatedAt
			beginsAt
			audienceSpace {
				id
				displayName
			}
			maxTicketCount
		}
	}
`;

interface RouteParams {
	identifier: string;
}

interface IShowDetailsProps extends WithStyles<typeof styles>, RouteComponentProps<RouteParams> {
	startTab: 'overview' | 'reservation' | 'audiencespace' | 'settings';
	showId: number;
}

interface IShowDetailsState {
	currentTab: 'overview' | 'reservation' | 'audiencespace' | 'settings';
}

class ShowDetails extends Component<IShowDetailsProps, IShowDetailsState> {
	componentWillMount() {
		const { startTab: tab } = this.props;
		this.setState({ currentTab: tab });
	}

	handleChange = (_: any, value: 'overview' | 'reservation' | 'audiencespace' | 'settings') => {
		const { history, match, showId } = this.props;
		this.setState({
			currentTab: value,
		});
		history.push(`/p/${match.params.identifier}/show/${showId}/${value}`);
	};

	handleCreateReservation = () => {
		const { history, match, showId } = this.props;
		history.push(`/p/${match.params.identifier}/reservation/create?showId=${showId}`);
	};

	render() {
		const { classes, showId } = this.props;
		const { currentTab } = this.state;
		return (
			<Query<getShowGeneral, getShowGeneralVariables> variables={{ id: showId }} query={GET_SHOW_GENERAL}>
				{({ data }) => {
					if (data && data.getShowById) {
						return (
							<div className={classes.root}>
								<Paper className={classes.tabs}>
									<div className={classes.content}>
										<div className={classes.row}>
											<div style={{ display: 'contents' }}>
												<Typography style={{ display: 'inline' }} variant="h3">
													{data.getShowById.displayName}
												</Typography>
												<Typography
													style={{ display: 'inline' }}
													className={classes.idText}
													variant="body2"
												>{`ID: ${showId}`}</Typography>
											</div>
											<span className={classes.spacer} />
											<Button onClick={this.handleCreateReservation} color="primary" variant="outlined">
												Reservierung hinzufügen
											</Button>
										</div>
										<Grid container direction="row" className={classes.details} spacing={0}>
											<Grid item xs={3} lg={2}>
												<Typography>Beginnt um:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getShowById.beginsAt}</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography>Zuletzt geupdated:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getShowById.lastUpdatedAt}</Typography>
											</Grid>
											<Grid item lg={4} />
											<Grid item xs={3} lg={2}>
												<Typography>Zuschauerraum:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">{data.getShowById.audienceSpace.displayName}</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography>Maximale Ticketanzahl:</Typography>
											</Grid>
											<Grid item xs={3} lg={2}>
												<Typography color="primary">
													{data.getShowById.maxTicketCount === -1
														? 'Kein Limit'
														: data.getShowById.maxTicketCount}
												</Typography>
											</Grid>
											<Grid item lg={4} />
										</Grid>
									</div>
									<Tabs
										value={currentTab}
										onChange={this.handleChange}
										indicatorColor="primary"
										textColor="primary"
										variant="fullWidth"
										centered
									>
										<Tab className={classes.tab} value="overview" label="Überblick" />
										<Tab className={classes.tab} value="reservation" label="Reservierungen" />
										<Tab className={classes.tab} value="audiencespace" label="Zuschauerraum" />
										<Tab className={classes.tab} value="settings" label="Einstellungen" />
									</Tabs>
								</Paper>

								{currentTab === 'reservation' && <ReservationList showId={showId} />}
								{currentTab === 'audiencespace' && <ShowAudienceSpaceView showId={showId} />}
								{currentTab === 'settings' && <ShowSettings showId={showId} />}
							</div>
						);
					} else {
						return null;
					}
				}}
			</Query>
		);
	}
}

export default withStyles(styles)(withRouter<IShowDetailsProps>(ShowDetails));
