import { Theme, createStyles } from '@material-ui/core';

const styles = ({ transitions, palette }: Theme) => {
	return createStyles({
		topbar: {
			position: 'fixed',
			width: '100%',
			top: 0,
			left: 0,
			right: 'auto',
			transition: transitions.create(['margin', 'width'], {
				easing: transitions.easing.sharp,
				duration: transitions.duration.leavingScreen,
			}),
		},
		topbarShift: {
			marginLeft: '271px',
			width: 'calc(-271px + 100vw)',
		},
		drawerPaper: {
			zIndex: 1200,
			width: '271px',
		},
		sidebar: {
			width: '270px',
		},
		content: {
			marginTop: '64px',
			backgroundColor: palette.background.default,
			transition: transitions.create('margin', {
				easing: transitions.easing.sharp,
				duration: transitions.duration.leavingScreen,
			}),
		},
		contentShift: {
			marginLeft: '270px',
		},
	});
};

export default styles;
