import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
	Grid,
	MenuItem,
	CircularProgress,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { createShowVariables, createShow } from '__schema__/createShow';
import {
	getAudienceSpacesShowCreateVariables,
	getAudienceSpacesShowCreate,
} from '__schema__/getAudienceSpacesShowCreate';

const GET_AUDIENCESPACES_BY_PROJECT = gql`
	query getAudienceSpacesShowCreate($id: Int!) {
		getProjectById(id: $id) {
			id
			audienceSpaces {
				id
				displayName
			}
		}
	}
`;

const CREATE_SHOW = gql`
	mutation createShow($audienceSpaceId: Int!, $projectId: Int!, $displayName: String!, $beginsAt: DateTime!) {
		createShow(
			createShowData: {
				projectId: $projectId
				audienceSpaceId: $audienceSpaceId
				displayName: $displayName
				beginsAt: $beginsAt
			}
		) {
			id
			disabled
			displayName
		}
	}
`;

interface ICreateShowDialogProps extends WithMobileDialog, WithStyles<typeof styles> {
	open: boolean;
	projectId: number;
	handleClose?: () => void;
	handleCreated?: () => void;
}

const CreateShowDialog: React.FC<ICreateShowDialogProps> = function(props: ICreateShowDialogProps) {
	const { fullScreen, open, handleClose, projectId, handleCreated } = props;

	return (
		<Query<getAudienceSpacesShowCreate, getAudienceSpacesShowCreateVariables>
			query={GET_AUDIENCESPACES_BY_PROJECT}
			variables={{ id: projectId }}
		>
			{({ data, loading }) => (
				<Mutation<createShow, createShowVariables> mutation={CREATE_SHOW}>
					{(createShow, { error }) => {
						return (
							<Formik
								initialValues={{
									displayName: '',
									audienceSpace: -1,
									date: new Date().toISOString().substr(0, 10) + 'T20:00',
								}}
								validate={values => {
									return validate(values, schema);
								}}
								onSubmit={async (values, { setSubmitting }) => {
									try {
										const result = await createShow({
											variables: {
												displayName: values.displayName,
												projectId: projectId,
												audienceSpaceId: values.audienceSpace,
												beginsAt: new Date(values.date),
											},
										});
										if (result != null && result.data != null && result.data.createShow != null) {
											setSubmitting(false);
											if (handleCreated) {
												handleCreated();
											}
											if (handleClose) {
												handleClose();
											}
										} else {
											setSubmitting(false);
										}
									} catch {
										setSubmitting(false);
									}
								}}
								render={props => (
									<Dialog
										fullScreen={fullScreen}
										open={open}
										onClose={handleClose}
										aria-labelledby="responsive-dialog-title"
									>
										<DialogTitle id="responsive-dialog-title">Neue Show erstellen</DialogTitle>
										<DialogContent>
											<DialogContentText>Erstelle eine neue Show</DialogContentText>
											{!loading && data != null && data.getProjectById != null && (
												<Form>
													<Grid container spacing={2}>
														<Grid item xs={12}>
															<Field
																fullWidth
																name="displayName"
																label="Anzeigename"
																variant="outlined"
																component={TextField}
															/>
														</Grid>
														<Grid item xs={12}>
															<Field
																fullWidth
																name="date"
																type="datetime-local"
																label="Datum"
																variant="outlined"
																component={TextField}
															/>
														</Grid>
														<Grid item xs={6}>
															<Field
																fullWidth
																name="audienceSpace"
																label="Zuschauerraum"
																select
																variant="outlined"
																component={TextField}
															>
																<MenuItem disabled key={-1} value={-1}>
																	---
																</MenuItem>
																{data.getProjectById.audienceSpaces.map(audienceSpace => (
																	<MenuItem key={audienceSpace.id} value={audienceSpace.id}>
																		{audienceSpace.displayName}
																	</MenuItem>
																))}
															</Field>
														</Grid>
														<Grid item xs={6} />
													</Grid>
												</Form>
											)}
											{loading && <CircularProgress />}
											{error && <SnackbarContent message={error.message} />}
										</DialogContent>
										{props.isSubmitting && <LinearProgress />}
										<DialogActions>
											<Button onClick={handleClose} color="primary">
												Abbrechen
											</Button>
											<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
												Show erstellen
											</Button>
										</DialogActions>
									</Dialog>
								)}
							/>
						);
					}}
				</Mutation>
			)}
		</Query>
	);
};

export default withMobileDialog()(withStyles(styles)(CreateShowDialog));
