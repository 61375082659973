import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
}

const AlertDialog: React.FC<Props> = function(props: Props) {
	const { open, handleClose, handleConfirm } = props;
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Reservierung löschen?</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Willst du die Reservierung tatsächlich löschen? Dieser Schritt kann nicht rückgängig gemacht werden.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Abbrechen
				</Button>
				<Button onClick={handleConfirm} color="primary" autoFocus>
					Löschen
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
