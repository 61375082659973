import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog, { WithMobileDialog } from '@material-ui/core/withMobileDialog';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	DialogContentText,
	withStyles,
	WithStyles,
	LinearProgress,
	SnackbarContent,
	Grid,
} from '@material-ui/core';

import styles from './styles';

import validate from 'validate.js';
import schema from './schema';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import {
	createReservationPriorityVariables,
	createReservationPriority,
} from '__schema__/createReservationPriority';

const CREATE_RESERVATION_PRIORITY = gql`
	mutation createReservationPriority($projectId: Int!, $displayName: String!, $value: Int!, $color: String!) {
		createReservationPriority(
			createReservationPriorityData: {
				projectId: $projectId
				displayName: $displayName
				value: $value
				color: $color
			}
		) {
			id
			displayName
			value
			color
		}
	}
`;

interface ICreateReservationPriorityDialogProps extends WithMobileDialog, WithStyles<typeof styles> {
	open: boolean;
	projectId: number;
	handleClose?: () => void;
	handleCreated?: () => void;
}

const CreateReservationPriorityDialog: React.FC<ICreateReservationPriorityDialogProps> = function(
	props: ICreateReservationPriorityDialogProps,
) {
	const { fullScreen, open, handleClose, classes, projectId, handleCreated } = props;

	return (
		<Mutation<createReservationPriority, createReservationPriorityVariables>
			mutation={CREATE_RESERVATION_PRIORITY}
		>
			{(createReservationPriority, { error }) => {
				return (
					<Formik
						initialValues={{ displayName: '', color: '#ffffff', value: 10 }}
						validate={values => {
							return validate(values, schema);
						}}
						onSubmit={async (values, { setSubmitting }) => {
							try {
								const result = await createReservationPriority({
									variables: {
										...values,
										projectId: projectId,
										value: Number(values.value),
									},
								});
								if (result != null && result.data != null && result.data.createReservationPriority != null) {
									setSubmitting(false);
									if (handleCreated) {
										handleCreated();
									}
									if (handleClose) {
										handleClose();
									}
								} else {
									setSubmitting(false);
								}
							} catch {
								setSubmitting(false);
							}
						}}
						render={props => (
							<Dialog
								fullScreen={fullScreen}
								open={open}
								onClose={handleClose}
								aria-labelledby="responsive-dialog-title"
							>
								<DialogTitle id="responsive-dialog-title">Neue Priorität erstellen</DialogTitle>
								<DialogContent>
									<DialogContentText>Erstelle eine neue Priorität</DialogContentText>
									<Form>
										<Field
											fullWidth
											className={classes.field}
											name="displayName"
											label="Anzeigename"
											variant="outlined"
											component={TextField}
										/>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<Field
													fullWidth
													className={classes.field}
													name="color"
													label="Farbe"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
											<Grid item xs={6}>
												<Field
													fullWidth
													className={classes.field}
													name="value"
													label="Wert"
													variant="outlined"
													component={TextField}
												/>
											</Grid>
										</Grid>
									</Form>
									{error && <SnackbarContent message={error.message} />}
								</DialogContent>
								{props.isSubmitting && <LinearProgress />}
								<DialogActions>
									<Button onClick={handleClose} color="primary">
										Abbrechen
									</Button>
									<Button onClick={props.handleSubmit as (() => void)} color="primary" autoFocus>
										Priorität erstellen
									</Button>
								</DialogActions>
							</Dialog>
						)}
					/>
				);
			}}
		</Mutation>
	);
};

export default withMobileDialog()(withStyles(styles)(CreateReservationPriorityDialog));
