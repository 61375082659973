import { Theme, createStyles } from '@material-ui/core';

const styles = ({ spacing }: Theme) => {
	return createStyles({
		root: {
			padding: spacing(3),
		},
		toolbar: {
			borderBottomWidth: '1px',
			borderBottomColor: '#DFE3E8',
			borderBottomStyle: 'solid',
			padding: spacing(0.5),
		},
		searchInputContainer: {
			borderBottomWidth: '1px',
			borderBottomColor: '#DFE3E8',
			borderBottomStyle: 'solid',
			padding: spacing(0.5),
		},
		searchInput: {
			height: 36,
		},
		rightSideBar: {
			borderLeftWidth: '1px',
			borderLeftColor: '#DFE3E8',
			borderLeftStyle: 'solid',
		},
		listView: {
			padding: spacing(0.5),
		},
		spacer: {
			flexGrow: 1,
		},
	});
};

export default styles;
