import React, { Component } from 'react';
import { TableRow, TableCell, Typography, WithStyles, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import styles from './styles';
import { EditOutlined } from '@material-ui/icons';

interface IReservationPriorityRowProps extends WithStyles<typeof styles> {
	onEditClick: (data: { color: string; displayName: string; id: number; value: number }) => void;
	color: string;
	displayName: string;
	id: number;
	value: number;
	createdAt: string;
}

interface IReservationPriorityRowState {
	editMode: boolean;
}

class ReservationPriorityRow extends Component<IReservationPriorityRowProps, IReservationPriorityRowState> {
	state = {
		editMode: false,
	};

	enterEditMode = () => {
		this.props.onEditClick({ ...this.props });
	};

	exitEditMode = () => {
		this.setState({
			editMode: false,
		});
	};

	render() {
		const { classes, displayName, id, value, createdAt, color } = this.props;
		const { editMode } = this.state;
		if (!editMode) {
			return (
				<TableRow className={classes.tableRow} hover key={id}>
					<TableCell className={classes.avatarCell}>
						<div style={{ backgroundColor: color }} className={classes.avatar} />
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Typography className={classes.nameText} variant="body1">
							{displayName}
						</Typography>
					</TableCell>
					<TableCell className={classes.tableCell}>{id}</TableCell>
					<TableCell className={classes.tableCell}>{value}</TableCell>
					<TableCell className={classes.tableCell}>{createdAt}</TableCell>
					<TableCell className={classes.tableCell}>
						<Button color="primary" onClick={this.enterEditMode}>
							<EditOutlined />
						</Button>
					</TableCell>
				</TableRow>
			);
		} else {
			//TODO Edit Priority Dialog
			return null;
		}
	}
}

export default withStyles(styles)(ReservationPriorityRow);
