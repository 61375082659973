// Palette
//import palette from '../palette';

export default {
	outlined: {},
	contained: {
		background:
			'linear-gradient(70deg, rgba(0,85,230,1) 0%, rgba(0,151,255,1) 100%)',
		'&:hover': {
			background:
				'linear-gradient(70deg, rgba(0,95,255,1) 0%, rgba(0,185,255,1) 100%)',
		},
		'&.Mui-disabled': {
			color: '#f8fafcb5',
		},
	},
};
