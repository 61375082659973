import React, { Component } from 'react';

// Material helpers
import {
	withStyles,
	WithStyles,
	Grid,
	Card,
	CardActionArea,
	CardActions,
	Button,
	CardContent,
	Avatar,
	CircularProgress,
} from '@material-ui/core';

// GraphQL
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

// Material components
import { Typography } from '@material-ui/core';

// Shared layouts
import { Project as ProjectLayout } from 'layouts';

// Component styles
import styles from './styles';
import { Add as AddIcon, Error as ErrorIcon } from '@material-ui/icons';
import { CreateProjectDialog } from './components';
import { GetProjects } from '__schema__/GetProjects';
import { withRouter, RouteComponentProps } from 'react-router';

const GET_PROJECTS = gql`
	query GetProjects {
		getCurrentUser {
			projectMemberships {
				project {
					id
					displayName
					identifier
					image
					showCount
					reservationCount
				}
			}
		}
	}
`;

interface IProjectListProps extends WithStyles<typeof styles>, RouteComponentProps {
	className: string;
}

interface IProjectListState {
	createDialogOpen: boolean;
}

class ProjectList extends Component<IProjectListProps, IProjectListState> {
	constructor(props: IProjectListProps) {
		super(props);
		this.state = {
			createDialogOpen: false,
		};
	}

	handleOpenCreateDialog = () => {
		this.setState({
			createDialogOpen: true,
		});
	};

	handleCloseCreateDialog = () => {
		this.setState({
			createDialogOpen: false,
		});
	};

	render() {
		const { classes, history } = this.props;
		const { createDialogOpen } = this.state;

		return (
			<ProjectLayout>
				<div className={classes.root}>
					<div className={classes.content}>
						<div className={classes.introduction}>
							<Typography className={classes.headline} variant="h2" component="h2">
								Willkommen bei Resyotic
							</Typography>
							<Typography className={classes.subIntroduction} variant="body1">
								Nutzen sie Resyotic um einfach Reservierungen, Sitzpläne und Tickets zu erstellen.
								Automatisieren den Workflow um noch effizienter zu werden und das alles an einem Ort. Alles in
								Resyotic.
							</Typography>
							<Grid container direction="row">
								<Button className={classes.introductionButton} color="primary" variant="outlined">
									Quick Start
								</Button>
								<Button className={classes.introductionButton} color="primary" variant="outlined">
									Dokumentation
								</Button>
							</Grid>
						</div>
						<CreateProjectDialog open={createDialogOpen} handleClose={this.handleCloseCreateDialog} />
						<Query<GetProjects> query={GET_PROJECTS}>
							{({ loading, error, data }) => {
								return (
									<Grid container spacing={2}>
										<Grid item xs={12} sm={6} md={4}>
											<Card className={classes.card}>
												<CardActionArea className={classes.cardContent} onClick={this.handleOpenCreateDialog}>
													<CardContent>
														<div className={classes.center}>
															<AddIcon className={classes.addIcon} color="primary" />
														</div>
														<Typography
															className={classes.center}
															color="primary"
															gutterBottom
															variant="h4"
															component="h2"
														>
															Projekt hinzufügen
														</Typography>
													</CardContent>
												</CardActionArea>
												<CardActions>
													<div className={classes.center}>
														<Button size="small" color="primary">
															Quick Start Guide anschauen
														</Button>
													</div>
												</CardActions>
											</Card>
										</Grid>
										{loading && <CircularProgress />}
										{error && (
											<Grid item xs={12} sm={6} md={4}>
												<Card className={classes.card}>
													<CardActionArea className={classes.cardContent}>
														<CardContent>
															<div className={classes.center}>
																<ErrorIcon className={classes.addIcon} color="error" />
															</div>
															<Typography
																className={classes.center}
																color="error"
																gutterBottom
																variant="h4"
																component="h2"
															>
																Fehler aufgetreten
															</Typography>
														</CardContent>
													</CardActionArea>
												</Card>
											</Grid>
										)}
										{data &&
											data.getCurrentUser &&
											data.getCurrentUser.projectMemberships.map(projectMembership => {
												return (
													<Grid key={projectMembership.project.id} item xs={12} sm={6} md={4}>
														<Card className={classes.card}>
															<CardActionArea
																onClick={() => {
																	history.push(`/p/${projectMembership.project.identifier}/dashboard`);
																}}
																className={classes.cardContent}
															>
																<CardContent>
																	<Avatar
																		className={classes.avatar}
																		alt="Logo"
																		src={
																			projectMembership.project.image == null
																				? undefined
																				: projectMembership.project.image
																		}
																	/>
																	<Typography
																		className={classes.center}
																		gutterBottom
																		variant="h4"
																		component="h2"
																	>
																		{projectMembership.project.displayName}
																	</Typography>
																	<Typography
																		className={classes.center}
																		gutterBottom
																		variant="body2"
																		component="h2"
																	>
																		{projectMembership.project.identifier}
																	</Typography>
																</CardContent>
															</CardActionArea>
															<CardActions>
																<div className={classes.center}>
																	<Button size="small" color="primary">
																		{projectMembership.project.showCount} Shows
																	</Button>
																	<Button size="small" color="primary">
																		{projectMembership.project.reservationCount} Reservierungen
																	</Button>
																</div>
															</CardActions>
														</Card>
													</Grid>
												);
											})}
									</Grid>
								);
							}}
						</Query>
					</div>
				</div>
			</ProjectLayout>
		);
	}
}

export default withStyles(styles)(withRouter<IProjectListProps>(ProjectList));
